import axios from 'axios';

const login = (email, password) => {
  const API_URL = process.env.REACT_APP_API_URL;
  return axios({
    method: 'post',
    url: `${API_URL}v2/user/token`,
    data: { email, password },
  });
};

const authAxios = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('token');
  if (token) {
    return axios.create({
      baseURL: `${API_URL}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    delete axios.defaults.headers.common['Authorization'];
    throw new Error('No auth token found');
  }
};

const getACLPermissions = () => {
  return authAxios()
    .get(`v2/aclpermission/all?page=0&limit=50`)
    .then(res => {
      return res;
    })
    .catch(err => {
      console.log(err);
    });
};

const getRoles = () => {
  return authAxios()
    .get(`v2/role/all?page=0&limit=50`)
    .then(res => {
      return res;
    })
    .catch(err => {
      console.log(err);
    });
};

const activateRegistration = (password, activationKey) => {
  return axios.post(`v2/user/activateRegistration`, { password, activationKey }).then(res => {
    return res;
  });
};

const customerPortalLogin = email => {
  return authAxios()
    .put(`v2/user/resetPassword`, { email })
    .then(res => {
      return res;
    })
    .catch(err => {
      console.log(err);
    });
};

const resetPassword = ({ email }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  return axios({
    method: 'put',
    url: `${API_URL}v2/user/resetPassword`,
    data: { email: email },
  });
};

const registerUser = (email, firstName, lastName, checkedRoleIds) => {
  return authAxios()
    .post(`v2/user/registration`, { email, firstName, lastName, roleIds: checkedRoleIds })
    .then(res => {
      return res;
    })
    .catch(err => {
      console.log(err);
    });
};

const setSingleRoleForUser = (userId, roleId) => {
  return authAxios()
    .post(`v2/user/singleRoleForUser/${userId}`, { roleId })
    .then(res => {
      return res;
    })
    .catch(err => {
      console.log(err);
    });
};

const deleteSingleRoleForUser = (userId, roleId) => {
  return authAxios()
    .delete(`v2/user/singleRoleForUser/${userId}`, { roleId })
    .then(res => {
      return res;
    })
    .catch(err => {
      console.log(err);
    });
};

export {
  deleteSingleRoleForUser,
  setSingleRoleForUser,
  registerUser,
  login,
  authAxios,
  getACLPermissions,
  activateRegistration,
  customerPortalLogin,
  getRoles,
  resetPassword,
};
