import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  mTop: {
    marginTop: theme.spacing(3),
  },
  linkButton: {
    display: 'inline-flex',
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: 1.75,
    letterSpacing: '0.02857em',
    minWidth: '64px',
    padding: '6px 16px',
    borderRadius: '4px',
    color: '#fff',
    backgroundColor: '#1976d2',
    textDecoration: 'none',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
  },
}));
function ThankYou() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="md">
        <Typography variant="h4" gutterBottom component="h1" className={classes.mTop}>
          Thank You
        </Typography>
      </Container>
    </React.Fragment>
  );
}

export default ThankYou;
