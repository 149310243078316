import React from 'react';
import { useGlobalModalContext } from '../components/global-modal/GlobalModal';
import { ModalService } from '../components/global-modal/ModalService';
import { arbitrationInsuranceStyle } from './ArbitrationInsuranceStyles';
import { StepperComponent } from './components/stepper-component/StepperComponent';
import { StepperContent } from './components/stepper-content-component/StepperContent';
import { AsFile } from './components/upload-component/UploadComponent';
import {
  stepFiveFormFields,
  stepFourFormFields,
  stepOneFormFields,
  stepThreeFormFields,
  stepTwoFormFields,
} from './FormFields';
import { ArbitrationDto } from './models/ArbitrationDto';
import { ClaimCreateDto, DocumentDto } from './models/ClaimCreateDto';
import {
  createArbitrationQuote,
  createClaimArbitration,
  getQuoteById,
  sendInvoiceEmails,
} from './services/ArbitrationService';
import { useNavigate } from 'react-router-dom';
import { pageMode } from './WrapperPage';

type PageProps = {
  title: string;
  mode?: pageMode;
  id?: string | number | null;
};

export type StepProps = {
  title: string;
};

const initSteps: StepProps[] = [
  { title: 'Proposer Details' },
  { title: 'Case Supervisor & Opponent' },
  { title: 'Case' },
];

export function ArbitrationInsurance(props: PageProps) {
  /**
   * State
   */
  const { id } = props;
  const { mode } = props;
  const [pageData, setPageData] = React.useState(new ArbitrationDto());
  const [steps, setSteps] = React.useState<StepProps[]>(initSteps);
  const [submitRespose, setSubmitResponse] = React.useState<any>(null);
  const classes = arbitrationInsuranceStyle();
  const submitButtonIndex: number = 2;
  const proceedButtonIndex: number = 3;
  const completeButtonIndex: number = 4;

  const [files, setFiles] = React.useState<any[]>([]);

  const [claim, setClaim] = React.useState<ClaimCreateDto>(new ClaimCreateDto());
  let navigate = useNavigate();

  React.useEffect(() => {
    if (mode && mode === 'view') {
      getQuoteById(id).then((res: any) => {
        const { data } = res;
        data.proposerDto['companyName'] = data?.proposerDto['organisationName'];
        setPageData(data ? data : new ArbitrationDto());
      });
    }
  }, [mode]);

  /**
   * Modal service
   */
  const { showModal } = useGlobalModalContext();
  const { hideModal } = useGlobalModalContext();
  const showModalService = new ModalService(showModal, hideModal);

  /**
   * Handling Page Data update
   * @param e event from child
   * @param parentFieldName Handling nested objects (parent name)
   */
  function updateData(e: any, parentFieldName: string) {
    if (parentFieldName) {
      setPageData(prevState => {
        let newState = { ...prevState };
        newState[parentFieldName as keyof Object][e.target.name as keyof Object] = e.target.value;
        return newState;
      });
    } else {
      setPageData(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    }
  }

  /**
   * Function to handle update state
   * @param file File from child
   * @param isDeleted handle remove file
   */
  function updateDocuments(file: AsFile, isDeleted: boolean = false) {
    if (isDeleted) {
      let filteredFiles = files.filter(item => {
        return item.id !== file.id;
      });
      setFiles([...filteredFiles]);
    } else {
      setFiles(prevState => [...prevState, file]);
    }
  }

  /**
   * Handling specific component
   * @param address child data
   * @param parentFieldName Handling nested objects (parent name)
   */
  const setAddress = (address: any, parentFieldName: string) => {
    setPageData(prevState => {
      let newState = { ...prevState };
      newState[parentFieldName as keyof Object]['address' as keyof Object] = address;
      return newState;
    });
  };

  /**
   * Ref child functions
   */
  const handleNext = React.useRef(null);

  /**
   * Implement Submit button action which is passed to child
   */
  const submitData = (activeStep: number) => {
    //API CALL
    if (!id) {
      createArbitrationQuote(pageData)
        .then((res: any) => {
          const { data } = res;
          if (data.length) {
            setSubmitResponse({ ...data[0] });
            submitButtonIndex + 1 === steps.length ? handleAddStep({ title: 'Quote' }) : null;
            handleNext.current(activeStep);
          }
        })
        .catch((err: any) => {
          const { data } = err['response'];
          showModalService.showErrorModal(data?.error ?? err.name, data?.message, data?.status);
          // showModalService.showErrorModal(err.message, err.stack, '');
        });
    } else {
      setSubmitResponse({ ...pageData });
      submitButtonIndex + 1 === steps.length ? handleAddStep({ title: 'Quote' }) : null;
      handleNext.current(activeStep);
    }
  };

  React.useEffect(() => {
    if (submitRespose) {
      setClaim((prevState: any) => {
        let newState = { ...prevState };
        newState.carrierId = submitRespose.carrierId;
        newState.claimType = submitRespose.claimType;
        newState.policyNumber = submitRespose.policyNumber;
        newState.quoteId = submitRespose.quoteId;
        newState.total = submitRespose.total;
        return newState;
      });
    }
  }, [submitRespose]);
  /**
   * Implement Proceed button action which is passed to child
   */
  const proceedFn = (activeStep: number) => {
    // TODO API CALL NOW RETURNS policyID ???
    sendInvoiceEmails({
      proposerEmail: submitRespose.proposersEmailAddress,
      quoteId: submitRespose.quoteId,
      insuranceType: 'ARBITRATION',
      person: {
        address1: pageData?.proposerDto?.address?.address1,
        address2: pageData?.proposerDto?.address?.address2,
        address3: pageData?.proposerDto?.address?.address3,
        address4: pageData?.proposerDto?.address?.address4,
        address5: pageData?.proposerDto?.address?.address5,
        email: pageData?.proposerDto?.emailAddress,
        firstName: pageData?.proposerDto?.firstName,
        flatNo: pageData?.proposerDto?.address?.flatNo,
        lastName: pageData?.proposerDto?.lastName,
        phone: pageData?.proposerDto?.phone,
        postCode: pageData?.proposerDto?.address?.postCode,
        salutation: pageData?.proposerDto?.salutation,
        companyName: pageData?.proposerDto?.companyName,
      },
    })
      .then((res: any) => {
        const { data } = res;
        if (data) {
          proceedButtonIndex + 1 === steps.length
            ? handleAddStep({ title: 'Supporting Documents' })
            : null;
          handleNext.current(activeStep);
        }
      })
      .catch((err: any) => {
        const { data } = err['response'];
        showModalService.showErrorModal(undefined, data?.error, '');
      });
  };
  /**
   * Implement Complete button action which is passed to child
   */
  const completeFn = (activeStep: number) => {
    const combinedFiles: { files: File[]; documentsDto: DocumentDto[] } = completeFiles();
    createClaimArbitration(claim, combinedFiles)
      .then((_res: any) => {
        navigate('/thank-you');
      })
      .catch((err: any) => {
        const { data } = err['response'];
        showModalService.showErrorModal(undefined, data?.error, '');
      });
  };

  /**
   * Combine files and Attachemnets
   */
  function completeFiles(): { files: File[]; documentsDto: DocumentDto[] } {
    let filesToSend: File[] = [];
    let dtosToSend: DocumentDto[] = [];

    claim.claimDocuments.map(item => {
      filesToSend.push(item.file.file);
      dtosToSend.push(new DocumentDto({ description: item.description, fileName: item.fileName }));
    });
    files.map(item => {
      filesToSend.push(item.file);
      dtosToSend.push(new DocumentDto({ description: item.description, fileName: item.file.name }));
    });

    return { files: filesToSend, documentsDto: dtosToSend };
  }

  /**
   * Add new step
   * @param step
   */
  const handleAddStep = (step: StepProps) => {
    setSteps(prevState => [...prevState, step]);
  };

  /**
   * Handle Stepper Content
   * @param index active index of step
   * @param editFormRef
   */
  const getStepContent = (index: number, editFormRef: any) => {
    switch (index) {
      case 0:
        return (
          <StepperContent
            updateDataFn={updateData}
            customComponentFn={(e: any) => setAddress(e, 'proposerDto')}
            data={{ ...pageData }}
            form={[...stepOneFormFields]}
            ref={editFormRef}
            mode={mode}
          ></StepperContent>
        );
      case 1:
        return (
          <StepperContent
            updateDataFn={updateData}
            customComponentFn={(e: any) => setAddress(e, 'opponentDto')}
            data={{ ...pageData }}
            form={[...stepTwoFormFields]}
            ref={editFormRef}
            mode={mode}
          ></StepperContent>
        );
      case 2:
        return (
          <StepperContent
            updateDataFn={updateData}
            data={{ ...pageData }}
            form={[...stepThreeFormFields]}
            ref={editFormRef}
            mode={mode}
          ></StepperContent>
        );
      case 3:
        return (
          <StepperContent
            updateDataFn={() => console.log('No implementation')}
            data={{ ...submitRespose }}
            form={[...stepFourFormFields]}
            ref={editFormRef}
            mode={mode}
          ></StepperContent>
        );
      case 4:
        return (
          <StepperContent
            updateDataFn={updateDocuments}
            customComponentFn={setClaim}
            data={[...claim.claimDocuments]}
            form={[...stepFiveFormFields]}
            mode={mode}
          ></StepperContent>
        );
      default:
        return new Error('Unknown index...');
    }
  };
  return (
    <div className={classes.root}>
      <StepperComponent
        submitButtonIndex={submitButtonIndex}
        submitButtonFn={submitData}
        proceedButtonIndex={proceedButtonIndex}
        proceedButtonFn={proceedFn}
        completeButtonIndex={completeButtonIndex}
        completeButtonFn={completeFn}
        steps={[...steps]}
        getStepContent={getStepContent}
        handleNextRef={handleNext}
        mode={mode}
      ></StepperComponent>
    </div>
  );
}
