import React from 'react';
import { useGlobalModalContext } from './GlobalModal';
import * as yup from 'yup';
import { Dialog, Box, Paper, DialogContent, Typography, Button } from '@mui/material';
import TextField from '@material-ui/core/TextField';
import SegmentTitle from '../SegmentTitle';
import { styled } from '@mui/material/styles';
import Grid from '@material-ui/core/Grid';
import { Controller, useForm } from 'react-hook-form';
import { CurrencyInputMask } from '../../services/CurrencyMaskService';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { authAxios } from '../../services/AuthService';
import Alert from '@mui/material/Alert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
/* import toast, { Toaster } from 'react-hot-toast'; */

const Item = styled(Paper)(({ theme: { spacing, typography } }) => ({
  backgroundColor: '#467495',
  border: 'none',
  ...typography.body2,
  padding: spacing(1),
  textAlign: 'center',
  color: 'white',
  minHeight: '22px',
}));

const schema = yup.object().shape({
  settlementAmount: yup.string().required('Amount is required!'),
});

/**
 * Specific modal for Settlements
 */
export default function SettlementModal(): JSX.Element {
  const { hideModal, store } = useGlobalModalContext();
  const [calculated, setCalculated] = React.useState<boolean>(false);
  const [claimStatus, setClaimStatus] = React.useState<string>('');

  console.log(store.modalProps);
  const { organisation, policyNumber, coverLevel } = store.modalProps;

  const methods = useForm<any>({
    defaultValues: {
      settlementAmount: '',
      coverLevel: 0,
      paymentDue: 0,
    },
    resolver: yupResolver(schema),
  });
  const { control, handleSubmit, clearErrors, getValues, formState, setValue } = methods;

  React.useEffect(() => {
    setValue('coverLevel', coverLevel);
  }, [coverLevel]);

  const handleClose = (): void => {
    hideModal();
  };

  async function getPaymentDue() {
    try {
      const API_ENDPOINT = process.env.REACT_APP_API_URL;
      const { settlementAmount } = getValues();

      const payload = {
        policyNumber: policyNumber,
        settlementAmount: settlementAmount,
      };

      const axiosInstance = authAxios();
      const { data } = await axiosInstance.patch(
        `${API_ENDPOINT}api/v1/claim/claimCalculation`,
        payload
      );

      setValue('paymentDue', data.paymentDue);
      setClaimStatus(data.claimStatus);
      setCalculated(true);
    } catch (error) {
      console.log(error);
      toast.error(`Error ${error.message}`);
    }
  }

  const submit = handleSubmit(
    (data: any): void => {
      getPaymentDue();
    },
    (errors: any): void => {
      console.log('ERRORS', errors);
    }
  );

  const handleConfirm = async (): Promise<void> => {
    try {
      const API_ENDPOINT = process.env.REACT_APP_API_URL;
      const { settlementAmount, coverLevel, paymentDue } = getValues();

      const payload = {
        policyNumber: policyNumber,
        settlement: Number(settlementAmount),
        coverLevel: coverLevel,
        paymentDue: Number(paymentDue),
        claimStatus: claimStatus,
      };

      const axiosInstance = authAxios();
      const { data } = await axiosInstance.put(
        `${API_ENDPOINT}api/v1/claim/claimCalculationFinal`,
        payload
      );

      toast.success('Claim has been settled!');
    } catch (error) {
      toast.error(`There was a problem settling the claim. ${error}`);
    }

    setTimeout(() => {
      hideModal();
    }, 5000);
  };

  return (
    <Dialog onClose={handleClose} open={true}>
      <Box
        sx={{
          width: '500px',
          height: '470px',
        }}
      >
        <ToastContainer />
        <SegmentTitle title={'Settlement Amount'}></SegmentTitle>
        <DialogContent sx={{ rowGap: '20px' }}>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Typography sx={{ textAlign: 'left', lineHeight: 2 }}>Organisation</Typography>
            </Grid>
            <Grid item xs={7}>
              <Item sx={{ backgroundColor: 'white', color: '#467495' }}>{organisation}</Item>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Typography sx={{ textAlign: 'left', lineHeight: 2 }}>Policy</Typography>
            </Grid>
            <Grid item xs={7}>
              <Item sx={{ backgroundColor: 'white', color: '#467495' }}>{policyNumber}</Item>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: '1rem' }}>
            <Grid item xs={5}>
              <Typography sx={{ textAlign: 'left', lineHeight: 2 }}>Settlement Amount</Typography>
            </Grid>
            <Grid item xs={7}>
              <Controller
                name={'settlementAmount'}
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    name={'settlementAmount'}
                    id={`settlementAmount`}
                    disabled={false}
                    helperText={error ? error.message : ' '}
                    size="small"
                    error={!!error}
                    onChange={e => onChange(e)}
                    value={value ?? ''}
                    fullWidth={true}
                    variant="outlined"
                    FormHelperTextProps={{
                      style: { textAlign: 'center' },
                    }}
                    InputProps={{
                      readOnly: false,
                      inputComponent: CurrencyInputMask,
                      inputProps: {
                        id: `amount_input_id`,
                        style: { textAlign: 'center' },
                      },
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Typography sx={{ textAlign: 'left', lineHeight: 2 }}>Cover level</Typography>
            </Grid>
            <Grid item xs={7}>
              <Controller
                name={'coverLevel'}
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    name={'coverLevel'}
                    id={`coverLevel`}
                    disabled={false}
                    helperText={error ? error.message : ' '}
                    size="small"
                    error={!!error}
                    onChange={e => onChange(e)}
                    value={value ?? ''}
                    fullWidth={true}
                    variant="outlined"
                    FormHelperTextProps={{
                      style: { textAlign: 'center' },
                    }}
                    InputProps={{
                      readOnly: true,
                      inputComponent: CurrencyInputMask,
                      inputProps: {
                        id: `cover_level_id`,
                        style: { textAlign: 'center' },
                      },
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Typography sx={{ textAlign: 'left', lineHeight: 2 }}>Payment Due</Typography>
            </Grid>
            <Grid item xs={7}>
              <Controller
                name={'paymentDue'}
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    name={'paymentDue'}
                    id={`paymentDue.id`}
                    disabled={false}
                    helperText={error ? error.message : ' '}
                    size="small"
                    error={!!error}
                    onChange={e => onChange(e)}
                    value={value ?? ''}
                    fullWidth={true}
                    variant="outlined"
                    FormHelperTextProps={{
                      style: { textAlign: 'center' },
                    }}
                    InputProps={{
                      readOnly: true,
                      inputComponent: CurrencyInputMask,
                      inputProps: {
                        id: `payment_due_id`,
                        style: { textAlign: 'center' },
                      },
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container style={{ justifyContent: 'center', marginTop: '2rem' }}>
            {!calculated ? (
              <Button
                variant="contained"
                color="primary"
                style={{ fontSize: '0.6rem', backgroundColor: '#467495', minWidth: '130px' }}
                onClick={submit}
              >
                Next
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                style={{ fontSize: '0.6rem', backgroundColor: '#467495', minWidth: '130px' }}
                onClick={handleConfirm}
              >
                Confirm
              </Button>
            )}
          </Grid>
        </DialogContent>
      </Box>
    </Dialog>
  );
}
