import { authAxios } from './AuthService';

const API = `api/v1/quote`;
const INSURANCE_TYPE = `ARBITRATION`;
const ACCEPTED = 0;
const LIVE_PAID = 1;

const getQuotes = (type, page) => {
  return authAxios()
    .get(
      `${API}/arbitrationQuotes?insuranceType=${INSURANCE_TYPE}&acceptedOrLivePaid=${type}&page=${page}&limit=10`
    )
    .then(res => res);
};

const searchQuotes = ({ type, searchValue }) => {
  return authAxios()
    .get(
      `${API}/search?insuranceType=${INSURANCE_TYPE}&acceptedOrLivePaid=${type}&sort=renewalDate,desc&searchFields=policyNumber,organisation.organisation,organisation.firstName,organisation.lastName&searchValue=${searchValue}`
    )
    .then(res => res);
};

const setDeclineStatusOnQuote = id => {
  return authAxios()
    .patch(`${API}/setDeclineStatusOnQuote?quoteId=${id}&insuranceType=${INSURANCE_TYPE}`)
    .then(res => res);
};

const reconcileQuote = id => {
  return authAxios()
    .patch(`${API}/reconcileQuote?quoteId=${id}&insuranceType=${INSURANCE_TYPE}`)
    .then(res => res);
};

const approveQuote = id => {
  return authAxios()
    .patch(`${API}/setApprovedStatusOnQuote?quoteId=${id}`)
    .then(res => res);
};

const getDocuments = id => {
  return authAxios()
    .get(`${API}/getById/${id}`)
    .then(res => res);
};

const updateDocuments = (id, payload) => {
  return authAxios()
    .put(`${API}/updateById/${id}`, payload)
    .then(res => res);
};

export {
  getQuotes,
  searchQuotes,
  setDeclineStatusOnQuote,
  reconcileQuote,
  approveQuote,
  getDocuments,
  updateDocuments,
};
