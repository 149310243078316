import { makeStyles } from '@material-ui/core/styles';

const stepperStyle = makeStyles(theme => ({
  content: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: '10px',
  },

  stepperButtonGroup: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1rem',
  },

  stepperBox: {
    boxShadow:
      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
  },
}));
export { stepperStyle };
