//import InputBase from "@material-ui/core/InputBase";
import { DataGrid } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import React, { useEffect, useRef, useState, useContext } from 'react';
import {
  getArbitrationClaims,
  searchArbitrationClaims,
} from '../services/ClaimsArbitrationService';

import { useStyles } from '../styles/ClaimsStyle';
import Title from '../admin/Title';
import { DataGridColumnService } from '../admin/data-grid/DataGridHelperService';
import { dataGridStyles } from '../admin/data-grid/DataGridStyle';
import { CustomPagination } from '../admin/data-grid/CustomPaginator';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../store/auth-context';
import { patchNotes } from '../services/RenewalsService';

function getOrgName(params) {
  return params?.row?.organisationDtoResponse?.organisationName;
}

function getFirstName(params) {
  return params?.row?.organisationDtoResponse?.organisationFirstName;
}

function getLastName(params) {
  return params?.row?.organisationDtoResponse?.organisationLastName;
}

const columnService = new DataGridColumnService('GBP');
const columnsDefs = columnService.createCustomTableColumns(
  [
    { field: 'policyNumber', flex: 1, headerName: 'Policy Number' },
    { field: 'organisationName', flex: 1, headerName: 'Organisation', valueGetter: getOrgName },
    { field: 'firstName', flex: 1, valueGetter: getFirstName },
    { field: 'lastName', flex: 1, valueGetter: getLastName },
    { field: 'opponentName', flex: 1, headerName: 'Opponent Company' }, //change this when name of the field is known
    { field: 'fnolDate', flex: 1, headerName: 'Claim Date' },
    { field: 'claimStatus', flex: 1, width: 10, headerName: 'Status' },
  ],
  'super-app-theme--header'
);

const initialStateDataGrid = {
  sorting: { sortModel: [{ field: 'policyNumber', sort: 'desc' }] },
};

const actionsDefs = handleEditClaim => {
  return {
    field: 'actions',
    headerName: 'Actions',
    flex: 2,
    align: 'center',
    headerAlign: 'center',
    headerClassName: 'super-app-theme--header',
    renderCell: cellValues => (
      <Button
        id={cellValues.row.claimId}
        variant="contained"
        color="primary"
        style={{ fontSize: '0.6rem', backgroundColor: '#467495' }}
        onClick={handleEditClaim}
      >
        Claim
      </Button>
    ),
  };
};

const ClaimsArbitration = () => {
  const [claims, setClaims] = useState([]);
  const [search, setSearch] = useState('');
  const dataGridStyle = dataGridStyles();
  const classes = useStyles();
  const mountedRef = useRef(true);
  const rows = [];
  const coulumn1 = [];
  const rows1 = claims?.data?.content;
  let navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  const updateRowNotes = (row, newValue) => {
    claims.find(item => item.id === row.id).notes = newValue;
    setClaims([...claims]);
  };

  const handleNotes = (event, data) => {
    const dataToSend = { [event.target.name]: event.target.value };
    patchNotes(data?.quoteId, dataToSend)
      .then(() => {
        getClaims('')
          .then(res => {
            const claims = getFormatedClaims(res?.content);
            setClaims([...claims]);
          })
          .catch(err => console.error(err));
      })
      .catch(err => console.error(err));
  };

  const handleEditClaim = event => {
    const claimId = event?.target?.id || 0;
    if (claimId !== 0) {
      const URL = '/edit-claim/:' + claimId;
      const selectedClaim = claims.find(claim => claim.claimId === Number(claimId));
      authCtx.setClaim(selectedClaim);
      navigate(URL);
    } else {
      console.log('Wrong claim id provided for editing claim, claimId = ', claimId);
    }
  };

  const getFormatedClaims = data => {
    const formatedClaims = [];
    data.map(claim => formatedClaims.push({ ...claim, id: claim?.claimId }));
    return formatedClaims;
  };

  const getFormatedClaimsReducer = data => {
    const formatedClaims = [];
    data.map(claimArray =>
      claimArray.map(claim => formatedClaims.push({ ...claim, id: claim?.claimId }))
    );
    return formatedClaims;
  };

  // SEARCH ARBITRATION CLAIMS -> useEffect
  useEffect(async () => {
    if (!mountedRef.current) {
      try {
        const data = await searchArbitrationClaims(search);
        const searchedClaims = getFormatedClaimsReducer(data);
        setClaims([...searchedClaims]);
      } catch (err) {
        err => console.log(err);
      }
    }
  }, [search]);

  // GET ARBITRATION CLAIMS -> useEffect
  useEffect(async () => {
    let sort = 'id';
    let direction = 'asc';
    try {
      const data = await getArbitrationClaims(0, 5, sort, direction);
      if (data?.content) {
        const claims = getFormatedClaims(data?.content);
        setClaims([...claims]);
        mountedRef.current = false;
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  if (rows1 !== undefined) {
    for (let i = 0; i < rows1.length; i++) {
      rows.push(rows1[i][0]);
    }
    if (rows1.length > 0) {
      Object.keys(rows1[0][0]).map(function (key, index) {
        coulumn1.push({
          field: key,
          headerName: key,
          width: 140,
          headerClassName: 'super-app-theme--header',
        });
      });
    }

    coulumn1.push({
      field: 'actions',
      headerName: 'Actions',
      width: 300,
      headerClassName: 'super-app-theme--header',
      renderCell: () => (
        <ButtonGroup
          style={{ width: '200px' }}
          variant="contained"
          aria-label="outlined primary button group"
        >
          <Button disabled>Renew</Button>
          <Button
            style={{
              backgroundColor: 'white',
              color: 'black',
            }}
          >
            Update
          </Button>
          <Button color="error">Cancel</Button>
          <Button>Claim</Button>
        </ButtonGroup>
      ),
    });
  }

  const handleSearch = e => {
    setSearch(e.target.value);
  };

  rows.forEach(element => {
    element.id = element.quoteId;
  });

  useEffect(() => {
    if (!mountedRef.current) {
      setRowState(rows);
    } else {
      console.log(true);
    }
  }, []);

  return (
    <div className={classes.grow}>
      <div className={[classes.root, classes.root_ext].join(' ')}>
        <Title>Claims</Title>
        <Stack
          spacing={2}
          gap="5px"
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          mb={2}
        >
          <TextField
            size="small"
            onChange={handleSearch}
            InputProps={{
              endAdornment: (
                <React.Fragment>
                  <SearchIcon />
                </React.Fragment>
              ),
            }}
          />
        </Stack>
        <div style={{ height: 'auto', overflow: 'auto' }}>
          {
            <DataGrid
              style={{ fontSize: '0.875rem' }}
              disableColumnMenu
              pagination
              autoHeight={true}
              rows={claims}
              columns={[...columnsDefs, actionsDefs(handleEditClaim)]}
              initialState={initialStateDataGrid}
              pageSize={10}
              sx={{ ...dataGridStyle }}
              components={{
                Pagination: CustomPagination,
              }}
            />
          }
        </div>
      </div>
    </div>
  );
};

export default ClaimsArbitration;
