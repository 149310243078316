import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AuthContext from '../store/auth-context';
import { login } from '../services/AuthService';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://houseofinsurtech.com">
        InsurForce
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  error: {
    textsize: 'large',
    textAlign: 'center',
    fontWeight: '300',
    color: '#FF0011',
  },
}));

export const LoginPage = () => {
  const classes = useStyles();
  const navigation = useNavigate();

  const authCtx = useContext(AuthContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const [loginError, setLoginError] = useState(false);
  const [validate, setValidate] = useState(false);

  const handleNavigate = link => {
    navigation(link);
  };

  React.useEffect(() => {
    if (authCtx.isLoggedIn) {
      handleNavigate('/quotes');
    }
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    setValidate(true);
    setEmailError(false);
    setPasswordError(false);

    if (email === '') {
      setEmailError(true);
    }

    if (password === '') {
      setPasswordError(true);
    }

    if (email && password) {
      setLoginError(false);
      login(email, password)
        .then(res => {
          authCtx.login(res.data.token, res.data.userDto);
          if (res.status === 200) {
            handleNavigate('/quotes');
          } else {
            handleNavigate('/policies');
          }
        })
        .catch(err => {
          setLoginError(true);
        });
    }
  };
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Log in
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit} noValidate autoComplete="off">
          <TextField
            onChange={e => setEmail(e.target.value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            helperText={
              validate
                ? emailError || (emailRegex.exec(email) == null && emailError)
                  ? 'Email is required'
                  : emailRegex.exec(email) == null
                  ? 'Please enter a valid email'
                  : ''
                : ''
            }
            error={validate && (emailError || emailRegex.exec(email) == null)}
          />
          <TextField
            onChange={e => setPassword(e.target.value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            helperText={validate && passwordError ? 'Password is required' : ''}
            error={validate && passwordError}
          />
          {loginError && (
            <div className={classes.error}>
              <b>Username or password incorrect</b>
            </div>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Log In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href={'/recover-pass'}>Forgotten password ?</Link>
            </Grid>
            <Grid item></Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};
