const modalStyles = (borderColor: string, color: string, backgroundColor: string) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 400,
  backgroundColor: backgroundColor,
  border: `1px solid ${borderColor}`,
  boxShadow: 24,
  paddding: 0,
  color: color,
});

const dialogTitleStyle = (color: string) => ({
  color: color,
});

const buttonStyle = (backgroundColor: string, color: string) => ({
  backgroundColor: backgroundColor,
  color: color,
});

const dialogContentStyle = (borderColor: string) => ({
  borderTop: `2px solid ${borderColor}`,
  borderBottom: `2px solid ${borderColor}`,
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
});

export { modalStyles, dialogTitleStyle, buttonStyle, dialogContentStyle };
