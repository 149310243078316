import { authAxios } from '../../services/AuthService';

const API = 'api/v1/quote';
const createArbitrationQuote = quoteDtoRequest => {
  return authAxios()
    .post(`${API}/createArbitrationQuote`, { ...quoteDtoRequest })
    .then(res => res);
};

const sendInvoiceEmails = data => {
  return authAxios()
    .post(
      `${API}/sendInvoiceEmails?proposerEmail=${data.proposerEmail}&quoteId=${data.quoteId}&insuranceType=${data.insuranceType}`,
      { ...data?.person }
    )
    .then(res => res);
};

const createClaimArbitration = (claim, combinedFiles) => {
  let claimToSend = { ...claim };
  claimToSend.claimDocuments = [...combinedFiles.documentsDto];
  claimToSend = { ...claimToSend, type: claimToSend.typeOfQuote.toLowerCase() };
  let formData = new FormData();
  let blob = new Blob([JSON.stringify(claimToSend.claimDocuments)], { type: 'application/json' });

  combinedFiles.files.forEach(file => {
    formData.append(`files`, file);
  });

  formData.append('documents', blob);
  return authAxios()
    .put(`api/v1/quote/updateById/${claim.quoteId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(res => res);
};

const getQuoteById = id => {
  return authAxios()
    .get(`${API}/reviewOfQuote/${id}`)
    .then(res => res);
};

export { createArbitrationQuote, createClaimArbitration, sendInvoiceEmails, getQuoteById };
