import { authAxios } from './AuthService';

const API = `/api/v1/claim`;
const USER_API = `api/v1`;
const ARBITRATION = 'ARBITRATION';

const getArbitrationClaims = async (page = 0, size = 5, sort, direction) => {
  return authAxios()
    .get(`${API}/getClaims?page=0&limit=50&insuranceType=${ARBITRATION}`) //?sort='+sort+','+direction+'&page='+page+'&size='+size)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      console.log(err);
    });
};

const getClaimByID = id => {
  return authAxios()
    .get(`/api/v1/claim/claimWithQuoteAndMessages/${id}?insuranceType=${ARBITRATION}`)
    .then(res => res.data);
};

// UPDATE ARBITRATION CLAIM - SAVE CLAIM INFO
const saveArbitrationClaimInfo = async request => {
  return authAxios()
    .put(`${API}/updateClaim?insuranceType=${ARBITRATION}`, request)
    .then(res => res.data);
};

// SEARCH ARBITRATION CLAIM
const searchArbitrationClaims = search => {
  return authAxios()
    .get(
      `/api/v1/claim/quote-claims-search?searchFields=policyNumber,organisation.firstName,organisation.lastName,organisation.postCode,organisation.phone&searchValue=${search}&page=0&size=1&insuranceType=${ARBITRATION}`
    )
    .then(res => {
      return res?.data?.content;
    })
    .catch(err => {
      console.log(err);
    });
};

// UPDATE THIS AFTER BACKED SEARCH IS IMPLEMENTED
const getClaimDocument = async (fileName, carQuoteId) => {
  return authAxios()
    .get(
      `${USER_API}/claim-document/download?fileName=` +
        fileName +
        `&quoteId=` +
        carQuoteId +
        `&insuranceType=${ARBITRATION}`,
      {
        responseType: 'arraybuffer',
      }
    )
    .then(res => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    })
    .catch(err => {
      console.log(err);
    });
};

export {
  getArbitrationClaims,
  searchArbitrationClaims,
  saveArbitrationClaimInfo,
  getClaimByID,
  getClaimDocument,
};
