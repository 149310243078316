import AddressComponent from '../components/AddressComponent';
import { CurrencyInputMask } from '../services/CurrencyMaskService';
import { SectionFields } from './components/stepper-content-component/StepperContent';
import { AttachmentsComponent } from './components/upload-component/AttachmentsComponent';
import FileListComponent from './components/upload-component/FileListComponent';

export const stepOneFormFields: SectionFields[] = [
  {
    sectionTitle: 'Proposer',
    fields: [
      {
        fieldName: 'companyName',
        type: 'textField',
        label: 'Organisation',
        width: 100,
        requried: false,
        parentFieldName: 'proposerDto',
        isAlone: false,
        validationName: 'proposerDto.companyName',
      },
      {
        fieldName: 'salutation',
        type: 'dropdown',
        label: 'Salutation',
        isAlone: false,
        width: 50,
        requried: true,
        parentFieldName: 'proposerDto',
        fieldOptions: [
          { value: 'Mr', optionLabel: 'Mr' },
          { value: 'Mrs', optionLabel: 'Mrs' },
          { value: 'Ms', optionLabel: 'Ms' },
          { value: 'Dr', optionLabel: 'Dr' },
          { value: 'Sir', optionLabel: 'Sir' },
        ],
        validationName: 'proposerDto.salutation',
      },
      {
        fieldName: 'firstName',
        type: 'textField',
        label: 'First Name',
        width: 100,
        requried: true,
        parentFieldName: 'proposerDto',
        validationName: 'proposerDto.firstName',
      },
      {
        fieldName: 'lastName',
        type: 'textField',
        label: 'Last Name',
        width: 100,
        requried: true,
        parentFieldName: 'proposerDto',
        validationName: 'proposerDto.lastName',
      },
      {
        fieldName: 'emailAddress',
        type: 'textField',
        label: 'Email Address',
        width: 100,
        requried: true,
        parentFieldName: 'proposerDto',
        validationName: 'proposerDto.emailAddress',
        pattern:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        patternMessage: 'Not valid email address.',
      },
      {
        fieldName: 'phone',
        type: 'textField',
        label: 'Telephone',
        width: 100,
        requried: true,
        parentFieldName: 'proposerDto',
        validationName: 'proposerDto.phone',
        minLength: 10,
        maxLength: 11,
        pattern: /^0\d{9,10}$/,
        patternMessage: 'The telephone number should start with a 0, without country code.',
      },
      {
        fieldName: 'address',
        type: 'addressComponent',
        label: 'address',
        width: 100,
        requried: true,
        parentFieldName: 'proposerDto',
        component: AddressComponent,
      },
    ],
  },
];

export const stepTwoFormFields: SectionFields[] = [
  {
    sectionTitle: 'Case Supervisor',
    fields: [
      {
        fieldName: 'supervisorFirstName',
        type: 'textField',
        label: 'First Name',
        width: 100,
        requried: true,
        validationName: 'supervisorFirstName',
      },
      {
        fieldName: 'supervisorLastName',
        type: 'textField',
        label: 'Last Name',
        width: 100,
        requried: true,
        validationName: 'supervisorLastName',
      },
      {
        fieldName: 'supervisorEmail',
        type: 'textField',
        label: 'Email Address',
        width: 100,
        requried: true,
        validationName: 'supervisorEmail',
        pattern:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        patternMessage: 'Not valid email address.',
      },
      {
        fieldName: 'supervisorPhone',
        type: 'textField',
        label: 'Telephone',
        width: 100,
        requried: true,
        validationName: 'supervisorPhone',
        minLength: 10,
        maxLength: 11,
        pattern: /^0\d{9,10}$/,
        patternMessage: 'The telephone number should start with a 0, without country code.',
      },
    ],
  },
  {
    sectionTitle: 'Opponent',
    fields: [
      {
        fieldName: 'opponentName',
        type: 'textField',
        label: 'Opponent Organisation',
        width: 100,
        requried: true,
        validationName: 'opponentName',
      },
      {
        fieldName: 'opponentType',
        type: 'dropdown',
        label: 'Organisation Type',
        width: 50,
        requried: true,
        fieldOptions: [
          { value: 'INDIVIDUAL', optionLabel: 'Individual' },
          { value: 'TRUSTEE', optionLabel: 'Trustee' },
          { value: 'LTD_CO', optionLabel: 'Ltd. Co.' },
          { value: 'PLC', optionLabel: 'Plc.' },
          { value: 'PARTNERSHIP', optionLabel: 'Partnership' },
          { value: 'SOLE_TRADER', optionLabel: 'Sole Trader' },
          { value: 'GOVT_DEPT', optionLabel: 'Govt. Dept.' },
          { value: 'HEALTH_AUTHORITY', optionLabel: 'Health Authority' },
          { value: 'LOCAL_AUTHORITY', optionLabel: 'Local Authority' },
        ],
        validationName: 'opponentType',
      },
      {
        fieldName: 'salutation',
        type: 'dropdown',
        label: 'Salutation',
        isAlone: true,
        width: 50,
        requried: true,
        parentFieldName: 'opponentDto',
        fieldOptions: [
          { value: 'Mr', optionLabel: 'Mr' },
          { value: 'Mrs', optionLabel: 'Mrs' },
          { value: 'Ms', optionLabel: 'Ms' },
          { value: 'Dr', optionLabel: 'Dr' },
          { value: 'Sir', optionLabel: 'Sir' },
        ],
        validationName: 'opponentDto.salutation',
      },
      {
        fieldName: 'firstName',
        type: 'textField',
        label: 'First Name',
        width: 100,
        requried: true,
        parentFieldName: 'opponentDto',
        validationName: 'opponentDto.firstName',
      },
      {
        fieldName: 'lastName',
        type: 'textField',
        label: 'Last Name',
        width: 100,
        requried: true,
        parentFieldName: 'opponentDto',
        validationName: 'opponentDto.lastName',
      },
      {
        fieldName: 'emailAddress',
        type: 'textField',
        label: 'Email Address',
        width: 100,
        requried: true,
        parentFieldName: 'opponentDto',
        validationName: 'opponentDto.emailAddress',
        pattern:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        patternMessage: 'Not valid email address.',
      },
      {
        fieldName: 'phone',
        type: 'textField',
        label: 'Telephone',
        width: 100,
        requried: true,
        parentFieldName: 'opponentDto',
        validationName: 'opponentDto.phone',
        minLength: 10,
        maxLength: 11,
        pattern: /^0\d{9,10}$/,
        patternMessage: 'The telephone number should start with a 0, without country code.',
      },
      {
        fieldName: 'address',
        type: 'addressComponent',
        label: 'address',
        width: 100,
        requried: true,
        parentFieldName: 'opponentDto',
        component: AddressComponent,
      },
      {
        fieldName: 'opponentRepresentative',
        type: 'textField',
        label: 'Solicator/Representative',
        width: 100,
        requried: true,
        isAlone: true,
        validationName: 'opponentRepresentative',
      },
    ],
  },
];

export const stepThreeFormFields: SectionFields[] = [
  {
    sectionTitle: 'Case',
    fields: [
      {
        fieldName: 'question_1',
        type: 'paragraf',
        label: 'What type of case is this?',
        width: 100,
        requried: false,
        isAlone: false,
      },
      {
        fieldName: 'claimType',
        type: 'dropdown',
        label: 'Claim Type',
        width: 100,
        requried: true,
        isAlone: false,
        fieldOptions: [
          { value: 'PURSUIT_OF_QUANTUM', optionLabel: 'Pursuit of Quantum' },
          { value: 'DEFENCE_OF_QUANTUM', optionLabel: 'Defence of Quantum' },
          { value: 'PURSUIT_OF_EXTENSION_OF_TIME', optionLabel: 'Pursuit of extension of time' },
          { value: 'DEFENCE_OF_EXTENSION_OF_TIME', optionLabel: 'Defence of extension of time' },
        ],
        validationName: 'claimType',
      },
      {
        fieldName: 'question_2',
        type: 'paragraf',
        label: 'What is the Disputed amount',
        width: 100,
        requried: true,
        isAlone: false,
      },
      {
        fieldName: 'amountDisputed',
        type: 'textField',
        label: 'Amount Disputed',
        width: 100,
        requried: true,
        isAlone: false,
        inputComponent: CurrencyInputMask,
        maskProps: { decimalScale: 2 },
        validationName: 'amountDisputed',
      },
      {
        fieldName: 'question_3',
        type: 'paragraf',
        label: 'What is the reasonably expected loss/award?',
        width: 100,
        requried: true,
        isAlone: false,
      },
      {
        fieldName: 'amountExpected',
        type: 'textField',
        label: 'Amount Expected',
        width: 100,
        requried: true,
        isAlone: false,
        inputComponent: CurrencyInputMask,
        maskProps: { decimalScale: 2 },
        validationName: 'amountExpected',
      },
      {
        fieldName: 'question_4',
        type: 'paragraf',
        label: 'How likely is the Adjudication to succeed?',
        width: 100,
        requried: false,
        isAlone: false,
      },
      {
        fieldName: 'prospectOfSuccess',
        type: 'dropdown',
        label: 'Prospect Of Success',
        isAlone: false,
        width: 50,
        requried: true,
        fieldOptions: [
          { value: 0.65, optionLabel: '65%' },
          { value: 0.7, optionLabel: '70%' },
          { value: 0.75, optionLabel: '75%' },
          { value: 0.8, optionLabel: '80%' },
          { value: 0.85, optionLabel: '85%' },
          { value: 0.9, optionLabel: '90%' },
          { value: 0.95, optionLabel: '95%' },
          { value: 1, optionLabel: '100%' },
        ],
        validationName: 'prospectOfSuccess',
      },
      {
        fieldName: 'question_5',
        type: 'paragraf',
        label: `Has a QS's opinion been sought?`,
        width: 100,
        requried: false,
        isAlone: false,
      },
      {
        fieldName: 'qsOpinionSOught',
        type: 'radioButtons',
        label: 'QS Opinion Sought',
        width: 100,
        requried: true,
        isAlone: false,
        validationName: 'qsOpinionSOught',
      },
      {
        fieldName: 'question_6',
        type: 'paragraf',
        label: `Has Counsel's opinion been sought?`,
        width: 100,
        requried: false,
        isAlone: false,
      },
      {
        fieldName: 'counselOpinionSought',
        type: 'radioButtons',
        label: 'Counsel Opinion Sought',
        width: 100,
        requried: true,
        isAlone: false,
        validationName: 'counselOpinionSought',
      },
      {
        fieldName: 'question_7',
        type: 'paragraf',
        label: `How much cover do you want to insure?`,
        width: 100,
        requried: false,
        isAlone: false,
      },
      {
        fieldName: 'coverLevel',
        type: 'textField',
        label: 'Cover Level',
        width: 100,
        requried: true,
        isAlone: false,
        inputComponent: CurrencyInputMask,
        maskProps: { decimalScale: 2 },
        validationName: 'coverLevel',
      },
    ],
  },
];

export const stepFourFormFields: SectionFields[] = [
  {
    sectionTitle: 'Quote',
    fields: [
      {
        fieldName: 'gross_premium',
        type: 'textField',
        label: 'Premium',
        width: 50,
        requried: false,
        isAlone: true,
        disabled: false,
        inputComponent: CurrencyInputMask,
        maskProps: { decimalScale: 2 },
        validationName: 'gross_premium',
        readOnly: true,
      },

      {
        fieldName: 'ipt_amt',
        type: 'textField',
        label: 'IPT',
        width: 50,
        requried: false,
        isAlone: true,
        disabled: false,
        inputComponent: CurrencyInputMask,
        maskProps: { decimalScale: 2 },
        validationName: 'ipt_amt',
        readOnly: true,
      },

      {
        fieldName: 'total',
        type: 'textField',
        label: 'Total Due',
        width: 50,
        requried: false,
        isAlone: true,
        disabled: false,
        inputComponent: CurrencyInputMask,
        maskProps: { decimalScale: 2 },
        validationName: 'total',
        readOnly: true,
      },
      {
        fieldName: 'question_4',
        type: 'paragraf',
        label: `If you choose to proceed with this policy an invoice will be sent to your email address with full payent details, when payment is received the Policy documents will be issued and the policy will be set in force.`,
        width: 100,
        requried: false,
        isAlone: true,
      },
      {
        fieldName: 'question_5',
        type: 'paragraf',
        label: `Do you wish to purchase this policy?`,
        width: 100,
        requried: false,
        isAlone: true,
      },
      {
        fieldName: 'question_6',
        type: 'component',
        label: ``,
        width: 100,
        requried: false,
        isAlone: true,
        component: FileListComponent,
        componentProps: { id: '' },
      },
    ],
  },
];
export const stepFiveFormFields: SectionFields[] = [
  {
    sectionTitle: 'Supporting Documents',
    fields: [
      {
        fieldName: 'question_1',
        type: 'paragraf',
        label: `Please supply the supporting documentation`,
        width: 100,
        requried: false,
        isAlone: true,
      },
      {
        fieldName: 'upload_1',
        type: 'upload',
        label: `QS Opinion`,
        width: 100,
        requried: false,
        isAlone: true,
      },
      //
      {
        fieldName: 'upload_2',
        type: 'upload',
        label: `Counsel Opinion`,
        width: 100,
        requried: false,
        isAlone: true,
      },
      //
      {
        fieldName: 'upload_3',
        type: 'upload',
        label: `Case Summary`,
        width: 100,
        requried: false,
        isAlone: true,
      },
      //
      {
        fieldName: 'upload_4',
        type: 'upload',
        label: `Copy of Contract`,
        width: 100,
        requried: false,
        isAlone: true,
      },
      //
      // {
      //   fieldName: 'upload_5',
      //   type: 'upload',
      //   label: `Experts Report`,
      //   width: 100,
      //   requried: false,
      //   isAlone: true,
      // },
      //
    ],
  },
  {
    sectionTitle: 'Other Supporting Documents',
    fields: [
      {
        fieldName: 'question_4',
        type: 'component',
        label: ``,
        width: 100,
        requried: false,
        isAlone: false,
        component: AttachmentsComponent,
        componentProps: { id: 'component__' },
      },
    ],
  },
];
