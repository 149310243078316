import React, { useEffect, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DateRangePicker from '@mui/lab/DateRangePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { DataGrid, GridToolbarExport } from '@mui/x-data-grid';
import { getReports, searchReports } from '../services/ReportsArbitrationService';
import { useStyles } from '../styles/ReportStyle';
import { DataGridColumnService } from '../admin/data-grid/DataGridHelperService';
import { CustomPagination } from '../admin/data-grid/CustomPaginator';
import Title from '../admin/Title';
import { dataGridStyles } from '../admin/data-grid/DataGridStyle';

const columnService = new DataGridColumnService('GBP');
const columnsDefs = columnService.createCustomTableColumns(
  [
    { field: 'createdOn', headerName: 'Date', flex: 1, isDate: true },
    { field: 'insurer', headerName: 'Insurer', flex: 1 },
    { field: 'product', headerName: 'Product', flex: 1 },
    { field: 'policyNumber', headerName: 'Policy Number', flex: 1 },
    { field: 'total', headerName: 'Premium inc. IPT', flex: 1 },
    /*  { field: 'totalDueRefund', headerName: 'Total Due/Refund', flex: 1, currency: false }, */
    { field: 'firstName', flex: 1 },
    { field: 'lastName', flex: 1 },
    { field: 'address1', flex: 1, isCellExpand: true },
    { field: 'address2', flex: 1, isCellExpand: true },
    { field: 'city', headerName: 'City or Town', flex: 1 },
    { field: 'postCode', flex: 1, isToUpperCase: true },
    { field: 'status', flex: 1 },
    { field: 'netPremium' },
    { field: 'grossPremium' },
    { field: 'iptAmt' },
  ],
  'super-app-theme--header'
);

const ReportsArbitration = () => {
  const [reports, setReports] = useState([]);
  const [search, setSearch] = useState('');
  const classes = useStyles();
  const dataGridStyle = dataGridStyles();

  const mountedRef = useRef(true);

  const [dateRange, setDateRange] = useState([null, null]);
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  // pagination
  const [totalPages, setTotalPages] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(0);

  // pagination
  const handlePageChange = newPageNumber => {
    setPageNumber(newPageNumber);
  };

  const onOpen = () => {
    setDatePickerOpen(true);
  };

  const onClose = () => {
    setDatePickerOpen(false);
  };
  useEffect(() => {
    getReports(pageNumber)
      .then(res => {
        setReports([...res.data.content]);
        setTotalPages(res.data.totalPages);
        mountedRef.current = false;
      })
      .catch(err => console.log(err));
  }, []);

  useEffect(() => {
    if (!mountedRef.current) {
      setDateRange([null, null]);
      searchReports(search)
        .then(res => setReports([...res?.data?.content]))
        .catch(err => console.log(err));
    }
  }, [search]);

  useEffect(() => {
    if (!mountedRef.current && dateRange[0] && dateRange[1]) {
      searchReports(search)
        .then(res => {
          const startDate = new Date(dateRange[0]);
          const endDate = new Date(dateRange[1]);
          let datesInRange = res?.data?.content.filter(report => {
            return new Date(report.createdOn) >= startDate && new Date(report.createdOn) <= endDate;
          });
          setReports([...datesInRange]);
          setDatePickerOpen(false);
        })
        .catch(err => console.log(err));
    }
  }, [dateRange]);

  const handleSearch = e => {
    setSearch(e.target.value);
  };

  reports.forEach(element => {
    element.id = element.quoteId;
  });
  return (
    <div className={classes.grow}>
      <div className={[classes.root, classes.root_ext].join(' ')}>
        <Title> Reports</Title>
        {/* <Button
          variant="contained"
          color="primary"
          style={{ fontSize: '0.6rem', backgroundColor: '#467495', marginBottom: '1rem' }}
          onClick={e => {}}
        >
          Download Bordereau
        </Button> */}
        <Stack
          spacing={2}
          gap="5px"
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          mb={2}
        >
          <TextField
            onChange={handleSearch}
            InputProps={{
              endAdornment: (
                <React.Fragment>
                  <SearchIcon />
                </React.Fragment>
              ),
            }}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
              startText="Start date"
              adapterlocale="uk"
              endText="End date"
              value={dateRange}
              open={datePickerOpen}
              onChange={newValue => {
                setDateRange(newValue);
              }}
              onClose={onClose}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} onClick={onOpen} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField {...endProps} onClick={onOpen} />
                </React.Fragment>
              )}
            />
          </LocalizationProvider>
        </Stack>
        <DataGrid
          columnVisibilityModel={{
            status: false,
            iptAmt: false,
            netPremium: false,
            grossPremium: false,
          }}
          disableColumnMenu
          pagination
          autoHeight={true}
          rows={reports}
          columns={columnsDefs}
          pageSize={10}
          sx={{ ...dataGridStyle }}
          components={{
            Pagination: props => (
              <CustomPagination
                {...props}
                count={totalPages}
                page={pageNumber}
                onPageChange={handlePageChange}
              />
            ),
            Toolbar: GridToolbarExport,
          }}
          componentsProps={{
            toolbar: {
              csvOptions: {
                fileName: 'bordereau',
                utf8WithBom: true,
                allColumns: true,
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default ReportsArbitration;
