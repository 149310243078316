import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  root: {
    '& .MuiOutlinedInput-root': {
      height: '30px',
    },
    '& .Deleted': {
      backgroundColor: '#ffbcbc',
      color: 'white',
      '&:hover': {
        backgroundColor: '#ffbcbc !important',
      },
    },
    '& .Deleted.Mui-selected': {
      backgroundColor: '#ffbcbc !important',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 4, 3),
    position: 'relative',
  },
  modalContent: {
    '& h4': {
      marginTop: '20px',
      marginLeft: '12px',
    },
    display: 'flex',
    justifyContent: 'space-between',
    maxHeight: '20rem',
    overflow: 'auto',
  },
  modalHeaderClose: {
    position: 'absolute',
    top: '-2px',
    right: '-3px',
  },
  root_ext: {
    height: 400,
    width: '75%',
    margin: '2rem auto',
  },
  actions: {
    display: 'flex',
    columnGap: '5px',
  },
}));

export { useStyles };
