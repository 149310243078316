import { FormControlLabel, IconButton } from '@material-ui/core';
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import setAuthToken from '../common/setAuthToken';
import AuthContext from '../store/auth-context';

import Typography from '@material-ui/core/Typography';
import { DataGrid } from '@mui/x-data-grid';

import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';

import Checkbox from '@material-ui/core/Checkbox';
import { blue } from '@material-ui/core/colors';

import Fade from '@material-ui/core/Fade';

import TextField from '@material-ui/core/TextField';

import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import GroupIcon from '@material-ui/icons/Group';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import Alert from '@material-ui/lab/Alert';
//import React, { useContext, useEffect, useState } from "react";
import { hasAclPermissionName, hasAclRoleName } from '../common/acl';
import {
  deleteSingleRoleForUser,
  setSingleRoleForUser,
  getRoles,
  registerUser,
} from '../services/AuthService';
import { getUsers } from '../services/UserService';
import { useStyles } from '../styles/UserStyle';
import { dataGridStyles } from '../admin/data-grid/DataGridStyle';
import { CustomPagination } from '../admin/data-grid/CustomPaginator';
import Title from '../admin/Title';
import { DataGridColumnService } from '../admin/data-grid/DataGridHelperService';

const columnService = new DataGridColumnService('GBP');
const columnsDefs = columnService.createCustomTableColumns(
  [
    { field: 'id', flex: 0.2 },
    { field: 'email', flex: 2 },
    { field: 'firstName', flex: 1 },
    { field: 'lastName', flex: 1 },
  ],
  'super-app-theme--header'
);

const Users = () => {
  const authAxios = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');
    if (token) {
      return axios.create({
        baseURL: `${API_URL}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      delete axios.defaults.headers.common['Authorization'];
      throw new Error('No auth token found');
    }
  };

  const classes = useStyles();
  const dataGridStyle = dataGridStyles();

  const [users, setUsers] = useState({});
  const [open, setOpen] = useState(false);
  const [openPermission, setOpenPermission] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openRestore, setOpenRestore] = useState(false);
  const [showRoles, setShowRoles] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [userId, setUserId] = useState('');
  const [userPermission, setUserPermission] = useState({});
  const [aclpermissions, setAclPermissions] = useState({});
  const [rolespermissions, setRolePermissions] = useState({});
  const [checked, setChecked] = useState(false);
  const authCtx = useContext(AuthContext);
  const [checkedRoles, setCheckedRoles] = useState({});
  const [checkedRoleIds, setCheckedRoleIds] = useState([]);

  useEffect(() => {
    getUsers()
      .then(res => {
        setUsers(res);
        setAuthToken(authCtx.token);
      })
      .catch(err => console.log(err));
  }, [setUsers, authCtx.token, checked]);

  useEffect(() => {
    getRoles()
      .then(res => {
        setRolePermissions(res);
      })
      .catch(err => console.log(err));
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenPermission = () => {
    setOpenPermission(true);
  };

  const handleClosePermission = () => {
    setOpenPermission(false);
  };

  const handleOpenEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleOpenRestore = () => {
    setOpenRestore(true);
  };

  const handleCloseRestore = () => {
    setOpenRestore(false);
  };

  const handleShowRoles = e => {
    setOpen(false);
  };

  const handleSubmit = e => {
    e.preventDefault();

    registerUser(email, firstName, lastName, checkedRoleIds)
      .then(res => {
        getUsers()
          .then(result => {
            setUsers(result);
            handleShowRoles();
          })
          .catch(err => console.log(err));
        setUserId(res.data.userId);
        setShowRoles(true);
      })
      .catch(err => {
        alert(err.message);
      });
  };

  const handleCheckboxRoleAddUser = e => {
    const roleId = e.target.value;
    const isChecked = e.target.checked;

    if (isChecked) {
      setCheckedRoleIds(prevCheckedRoleIds => [...prevCheckedRoleIds, roleId]);
    } else {
      setCheckedRoleIds(prevCheckedRoleIds => prevCheckedRoleIds.filter(id => id !== roleId));
    }
  };

  const actionsDefs = [
    {
      field: 'edit',
      headerName: 'Edit',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: params =>
        hasAclRoleName('Administrator') && (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: 'pointer' }}
          >
            <EditUser index={params.row} />
          </div>
        ),
    },
    {
      field: 'delete',
      headerName: 'Delete',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: params =>
        hasAclRoleName('Administrator') && (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: 'pointer' }}
          >
            <DeleteUser index={params.row} />
          </div>
        ),
    },
    {
      field: 'permissions',
      headerName: 'Permissions',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: params =>
        hasAclRoleName('Administrator') && (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: 'pointer' }}
          >
            <EditPermissions index={params.row} />
          </div>
        ),
    },
  ];

  const EditUser = ({ index }) => {
    const handleEditClick = () => {
      handleOpenEdit();
      setUserId(index.id);
      setFirstName(index.firstName);
      setLastName(index.lastName);
    };

    return (
      <FormControlLabel
        control={
          <IconButton color="secondary" aria-label="edit user" onClick={handleEditClick}>
            <EditIcon style={{ color: blue[700] }} />
          </IconButton>
        }
      />
    );
  };

  const handleEditUserSubmit = e => {
    e.preventDefault();
    axios({
      method: 'put',
      url: `v2/user/update/${userId}`,
      data: {
        email,
        firstName,
        lastName,
        isDeleted: 0,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        getUsers()
          .then(result => {
            setUsers(result);
          })
          .catch(err => console.log(err));
        handleCloseEdit();
      })
      .catch(err => {
        alert(err.message);
      });
  };

  const DeleteUser = ({ index }) => {
    const handleDeleteClick = () => {
      handleOpenDelete();
      setUserId(index.id);
      setFirstName(index.firstName);
    };

    const handleRestoreClick = () => {
      handleOpenRestore();
      setUserId(index.id);
      setFirstName(index.firstName);
    };

    return (
      <>
        {!index.isDeleted && (
          <FormControlLabel
            control={
              <IconButton color="secondary" aria-label="delete user" onClick={handleDeleteClick}>
                <DeleteIcon />
              </IconButton>
            }
          />
        )}
        {index.isDeleted && (
          <FormControlLabel
            control={
              <IconButton color="secondary" aria-label="restore user" onClick={handleRestoreClick}>
                <RestoreFromTrashIcon style={{ color: blue[700] }} />
              </IconButton>
            }
          />
        )}
      </>
    );
  };

  const handleDeleteUserYes = () => {
    axios
      .delete(`v2/user/delete/${userId}`, {
        data: {
          index: userId,
        },
      })
      .then(res =>
        getUsers()
          .then(result => {
            setUsers(result);
          })
          .catch(err => console.log(err))
      );
    handleCloseDelete();
  };

  const handleRestoreUserYes = () => {
    axios
      .put(`v2/user/deleteStatusUpdate/${userId}`, {
        data: {
          isDeleted: 0,
        },
      })
      .then(res =>
        getUsers()
          .then(result => {
            setUsers(result);
          })
          .catch(err => console.log(err))
      );
    handleCloseRestore();
  };

  const EditPermissions = ({ index }) => {
    const handleEditPermission = () => {
      handleOpenPermission();
      setUserPermission(index);
      setFirstName(index.firstName);
    };

    return (
      <FormControlLabel
        control={
          <IconButton color="secondary" aria-label="edit user" onClick={handleEditPermission}>
            <GroupIcon style={{ color: blue[700] }} />
          </IconButton>
        }
      />
    );
  };

  const handleCheckboxRole = e => {
    if (e.target.checked) {
      return authAxios()
        .post(`v2/user/singleRoleForUser/${userPermission.id}`, {
          roleId: e.target.value,
        })
        .then(res => {
          setUserPermission(prevState => ({
            ...prevState,
            roleDtoResponseList: res.data.roleDtoResponseList,
          }));
          setChecked(prevState => !checked);
        });
    } else {
      rolepermiss?.data?.content[userPermission.id].roleDtoResponseList.splice(1, 1);
      // skine permisiju za rolu
      axios
        .delete(`v2/user/singleRoleForUser/${userPermission.id}`, {
          data: {
            roleId: e.target.value,
          },
        })
        .then(res => {
          setUserPermission(prevState => ({
            ...prevState,
            roleDtoResponseList: res.data.roleDtoResponseList,
          }));
          setChecked(prevState => !checked);
        });
    }
  };

  const rows = users?.data?.content
    ? users.data.content.map(user => {
        return {
          id: user.id,
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          aclPermissionDTOResponseList: user.aclPermissionDTOResponseList,
          roleDtoResponseList: user.roleDtoResponseList,
          isDeleted: !user.isDeleted ? '' : 'Deleted',
        };
      })
    : [];

  const rolepermiss = rolespermissions?.data?.content ? rolespermissions.data.content : [];

  return (
    <div className={classes.grow}>
      <div className={[classes.root, classes.root_ext].join(' ')}>
        <Title>Users</Title>
        <DataGrid
          disableColumnMenu
          autoHeight={true}
          rows={rows}
          columns={[...columnsDefs, ...actionsDefs]}
          pageSize={8}
          sx={{ ...dataGridStyle }}
          components={{
            Pagination: CustomPagination,
          }}
          getRowClassName={params => `${params.getValue(params.id, 'isDeleted')}`}
        />

        <div style={{ textAlign: 'right', marginTop: '2rem' }}>
          {hasAclRoleName('Administrator') && (
            <Button type="submit" color="primary" variant="contained" onClick={handleOpen}>
              Add User
            </Button>
          )}
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <IconButton
                className={classes.modalHeaderClose}
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
              <Typography variant="h3" gutterBottom component="h3" id="transition-modal-title">
                Add User: {!showRoles ? ' ' : firstName}
              </Typography>

              <form className={classes.form} onSubmit={handleSubmit} noValidate autoComplete="off">
                <div className={showRoles ? classes.hideContent : ' '}>
                  <TextField
                    onChange={e => setFirstName(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    name="firstName"
                    value={firstName}
                    autoFocus
                  />
                  <TextField
                    onChange={e => setLastName(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="lastName"
                    label="Last Name"
                    type="lastName"
                    id="lastName"
                    value={lastName}
                  />
                  <TextField
                    onChange={e => setEmail(e.target.value)}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="email"
                    label="Email"
                    type="email"
                    id="email"
                    value={email}
                    //autoComplete="current-password"
                    // error={passwordError}
                  />
                </div>
              </form>
              <div className={!showRoles ? classes.hideContent : ' '}>
                <div className={classes.modalContent}>
                  <div>
                    <Typography gutterBottom component="h4">
                      Roles
                    </Typography>
                    {rolepermiss.map(role => {
                      return (
                        <p key={role.id}>
                          <Checkbox
                            checked={checkedRoleIds.includes(role.id.toString())}
                            onChange={handleCheckboxRoleAddUser}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            color="primary"
                            name={role.name}
                            value={role.id}
                          />

                          {role.name}
                        </p>
                      );
                    })}
                  </div>
                  <div></div>
                </div>
                {hasAclRoleName('Administrator') && (
                  <Button
                    //type="submit"
                    onClick={handleSubmit}
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Register User
                  </Button>
                )}
              </div>
            </div>
          </Fade>
        </Modal>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openEdit}
          onClose={handleCloseEdit}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openEdit}>
            <div className={classes.paper}>
              <IconButton
                className={classes.modalHeaderClose}
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => {
                  setOpenEdit(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
              <Typography variant="h3" gutterBottom component="h3" id="transition-modal-title">
                Edit User: {firstName}
              </Typography>
              <form
                className={classes.form}
                onSubmit={handleEditUserSubmit}
                noValidate
                autoComplete="off"
              >
                <TextField
                  onChange={e => setFirstName(e.target.value)}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  name="firstName"
                  value={firstName}
                  autoFocus
                />
                <TextField
                  onChange={e => setLastName(e.target.value)}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="lastName"
                  label="Last Name"
                  type="lastName"
                  id="lastName"
                  value={lastName}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Save Changes
                </Button>
              </form>
            </div>
          </Fade>
        </Modal>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openPermission}
          onClose={handleClosePermission}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openPermission}>
            <div className={classes.paper}>
              <IconButton
                className={classes.modalHeaderClose}
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => {
                  setOpenPermission(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
              <Typography variant="h4" gutterBottom component="h3" id="transition-modal-title">
                Permissions for: {firstName}
              </Typography>
              <div className={classes.modalContent}>
                <div>
                  <Typography gutterBottom component="h4">
                    Roles
                  </Typography>
                  {rolepermiss.map(role => {
                    return (
                      <p key={role.id}>
                        <Checkbox
                          checked={
                            typeof userPermission?.roleDtoResponseList?.find(
                              x => x.id === role.id
                            ) === 'object'
                              ? true
                              : false
                          }
                          onChange={handleCheckboxRole}
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                          color="primary"
                          name={role.name}
                          value={role.id}
                        />
                        {role.name}
                      </p>
                    );
                  })}
                </div>
              </div>
              <Button
                type="button"
                variant="contained"
                className={classes.button}
                color="primary"
                onClick={() => {
                  setOpenPermission(false);
                }}
              >
                Save Changes
              </Button>
              <Button
                type="button"
                variant="outlined"
                color="primary"
                onClick={() => {
                  setOpenPermission(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </Fade>
        </Modal>
        <Modal
          aria-labelledby="transition-modal-delete"
          aria-describedby="transition-modal-delete"
          className={classes.modal}
          open={openDelete}
          onClose={handleCloseDelete}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openDelete}>
            <div className={classes.paper}>
              <IconButton
                className={classes.modalHeaderClose}
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => {
                  setOpenDelete(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>

              <div className={classes.modalContent}>
                <Alert variant="outlined" severity="error">
                  <Typography gutterBottom component="h5">
                    Are you sure you want to delete user: {firstName}
                  </Typography>
                  <Button variant="contained" color="secondary" onClick={handleDeleteUserYes}>
                    Yes
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.button}
                    onClick={() => {
                      setOpenDelete(false);
                    }}
                  >
                    No
                  </Button>
                </Alert>
              </div>
            </div>
          </Fade>
        </Modal>

        <Modal
          aria-labelledby="transition-modal-restore"
          aria-describedby="transition-modal-restore"
          className={classes.modal}
          open={openRestore}
          onClose={handleCloseRestore}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openRestore}>
            <div className={classes.paper}>
              <IconButton
                className={classes.modalHeaderClose}
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => {
                  setOpenRestore(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>

              <div className={classes.modalContent}>
                <Alert variant="outlined" severity="success">
                  <Typography gutterBottom component="h5">
                    Are you sure you want to restore user: {firstName}
                  </Typography>

                  <Button variant="contained" color="primary" onClick={handleRestoreUserYes}>
                    Yes
                  </Button>
                  <Button
                    variant="contained"
                    className={classes.button}
                    onClick={handleCloseRestore}
                  >
                    No
                  </Button>
                </Alert>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    </div>
  );
};

export default Users;
