import * as React from 'react';
import NumberFormat from 'react-number-format';

export interface MaskProps {
  decimalScale?: number;
  prefix?: string;
  allowNegative?: boolean;
  maxValue?: any;
}

const defaultValues: MaskProps = {
  decimalScale: 0,
  prefix: '£',
  allowNegative: false,
  maxValue: 1000000,
};

export function CurrencyInputMask(props: any) {
  const { inputRef, onChange, mask, ...other } = props;

  const MAX_VAL: number = mask?.maxValue ?? defaultValues.maxValue;
  const withValueLimit = ({ floatValue }: any) => {
    if (!floatValue) floatValue = 0;
    return floatValue <= MAX_VAL;
  };
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      displayType="input"
      thousandSeparator
      decimalScale={mask?.decimalScale ?? defaultValues.decimalScale}
      prefix={mask?.prefix ?? defaultValues.prefix}
      isAllowed={withValueLimit}
      allowNegative={mask?.allowNegative ?? defaultValues.allowNegative}
    />
  );
}
