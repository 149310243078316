import * as React from 'react';
import { MenuItem, RadioGroup, FormHelperText, FormControl } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';

import SegmentTitle from '../../../components/SegmentTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import { str2bool } from '../../Utils';
import { UploadComponent } from '../upload-component/UploadComponent';
import { MaskProps } from '../../../services/CurrencyMaskService';
import { SubmitHandler, useForm } from 'react-hook-form';
import { pageMode } from '../../WrapperPage';

export interface StepperContentProps {
  form?: SectionFields[];
  data?: any;
  updateDataFn: Function;
  customComponentFn?: Function;
  attachments?: any[];
  editFormRef?: any;
  mode?: pageMode;
}

export interface FieldDropdownOptions {
  value: string | number;
  optionLabel: string;
}

export interface Field {
  label: string;
  fieldName: string;
  parentFieldName?: string; // if field is nested
  type: FieldType;
  width: Width;
  isAlone?: boolean;
  requried: boolean;
  pattern?: RegExp; // if validation is pattern
  patternMessage?: string; // if validation is pattern add message
  minLength?: number; // if validatopn is min Length
  maxLength?: number; // if validatopn is max Length
  component?: any; // if field is custom component
  componentProps?: any; // provide props for custom component
  fieldOptions?: any[]; // if field is dropdown provide options
  disabled?: boolean;
  inputComponent?: any; // provide input component eg. mask
  maskProps?: MaskProps; // if masked field provide custom props for mask to override default values
  validationName?: string; // provide validation name for react-hook-form (eg nested parent.child )
  readOnly?: boolean;
}
export interface SectionFields {
  fields: Field[];
  sectionTitle: string;
}

type FieldType =
  | 'textField'
  | 'dropdown'
  | 'textArea'
  | 'addressComponent'
  | 'paragraf'
  | 'radioButtons'
  | 'upload'
  | 'component';
type Width = 100 | 75 | 50 | 25;

const customMenuProps: any = {
  anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
  getContentAnchorEl: null,
};

const paragrafStyle: any = {
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'space-around',
  placeContent: 'space-around center',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  fontSize: '1.1em',
};

export const StepperContent = React.forwardRef((props: StepperContentProps, ref) => {
  return <StepperContentRef {...{ ...props, editFormRef: ref }}></StepperContentRef>;
});

const StepperContentRef: React.FC<StepperContentProps> = (props: StepperContentProps) => {
  const [valid, setValid] = React.useState<boolean>(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<any>();

  /**
   *  Expose methods to parent component.
   */
  React.useImperativeHandle(props.editFormRef, () => ({
    async submitForm() {
      await handleSubmit(onSubmit, onError)();
    },
    isValid(): boolean {
      return valid;
    },
  }));

  const onSubmit: SubmitHandler<any> = data => {
    setValid(true);
  };

  const onError: SubmitHandler<any> = data => {
    setValid(false);
  };

  /**
   *
   * @param field provided field
   * @returns object to register in react-hook-form registry with validations
   * TODO other validations
   */
  function validationsTypes(field: Field): any {
    let validationObj = {
      required: `${field.label} is required`,
      pattern: {
        value: field.pattern,
        message: field.patternMessage ?? '',
      },
      minLength: {
        value: field.minLength,
        message: 'UK telephone numbers should contain 10 or 11 digits',
      },
      maxLength: {
        value: field.maxLength,
        message: 'UK telephone numbers should contain 10 or 11 digits',
      },
    };
    field.pattern ?? delete validationObj.pattern;
    !field.requried ? delete validationObj.required : '';
    field.minLength ?? delete validationObj.minLength;
    field.maxLength ?? delete validationObj.maxLength;
    return validationObj;
  }
  return (
    <>
      <form>
        {props.form &&
          props.form.map((section, sectionIndex) => {
            return (
              <div key={sectionIndex}>
                <div key={sectionIndex} style={{ marginTop: '20px', marginBottom: '20px' }}>
                  <SegmentTitle
                    title={section.sectionTitle}
                  ></SegmentTitle>
                </div>
                <Grid container spacing={2}>
                  {section.fields &&
                    section.fields.map((field, index) => {
                      switch (field.type) {
                        case 'textField':
                          return (
                            <Grid key={100 + index} item xs={12} sm={field.isAlone ? 12 : 6}>
                              <TextField
                                {...(props && props.mode === 'view'
                                  ? null
                                  : {
                                      ...register(field.validationName, {
                                        ...validationsTypes(field),
                                      }),
                                    })}
                                id={field.fieldName}
                                name={
                                  field.parentFieldName ? field.validationName : field.fieldName
                                }
                                required={field.requried}
                                key={index}
                                label={field.label}
                                disabled={field.disabled}
                                value={
                                  (field.parentFieldName
                                    ? props.data[field.parentFieldName][field.fieldName]
                                    : props.data[field.fieldName]) ?? ''
                                }
                                onChange={e => {
                                  field.parentFieldName
                                    ? props.updateDataFn(
                                        {
                                          target: { name: field.fieldName, value: e.target.value },
                                        },
                                        field.parentFieldName ?? ''
                                      )
                                    : props.updateDataFn(e, field.parentFieldName ?? '');
                                  document
                                    .getElementsByName(
                                      field.parentFieldName ? field.validationName : field.fieldName
                                    )[0]
                                    .focus();
                                }}
                                style={{
                                  width: `${field.isAlone ? field.width / 2 - 1 : field.width}%`,
                                }}
                                InputProps={{
                                  readOnly:
                                    props && props.mode === 'view' ? true : field.readOnly ?? false,
                                  inputComponent: field.inputComponent,
                                  inputProps: { mask: { ...field.maskProps } },
                                }}
                                error={
                                  field.parentFieldName
                                    ? errors[field.parentFieldName] &&
                                      errors[field.parentFieldName][field.fieldName]
                                      ? true
                                      : false
                                    : errors[field.fieldName]
                                    ? true
                                    : false
                                }
                                helperText={
                                  field.parentFieldName
                                    ? errors[field.parentFieldName] &&
                                      errors[field.parentFieldName][field.fieldName] &&
                                      errors[field.parentFieldName][field.fieldName].message
                                    : errors[field.fieldName] && errors[field.fieldName].message
                                }
                              ></TextField>
                            </Grid>
                          );
                        case 'dropdown':
                          return (
                            <Grid key={100 + index} item xs={12} sm={field.isAlone ? 12 : 6}>
                              <FormControl
                                variant="standard"
                                error={
                                  field.parentFieldName
                                    ? errors[field.parentFieldName] &&
                                      errors[field.parentFieldName][field.fieldName]
                                      ? true
                                      : false
                                    : errors[field.fieldName]
                                    ? true
                                    : false
                                }
                                style={{
                                  width: `${field.isAlone ? field.width / 2 : field.width}%`,
                                }}
                              >
                                <InputLabel id={field.fieldName}>{field.label}</InputLabel>
                                <Select
                                  {...(props && props.mode === 'view'
                                    ? null
                                    : {
                                        ...register(field.validationName, {
                                          ...validationsTypes(field),
                                        }),
                                      })}
                                  required={field.requried}
                                  id={field.fieldName}
                                  name={
                                    field.parentFieldName ? field.validationName : field.fieldName
                                  }
                                  inputProps={{
                                    name: field.parentFieldName
                                      ? field.validationName
                                      : field.fieldName,
                                  }}
                                  value={
                                    (field.parentFieldName
                                      ? props.data[field.parentFieldName][field.fieldName]
                                      : props.data[field.fieldName]) ?? ''
                                  }
                                  onChange={e => {
                                    field.parentFieldName
                                      ? props.updateDataFn(
                                          {
                                            target: {
                                              name: field.fieldName,
                                              value: e.target.value,
                                            },
                                          },
                                          field.parentFieldName ?? ''
                                        )
                                      : props.updateDataFn(e, field.parentFieldName ?? '');
                                  }}
                                  style={{ width: '100%' }}
                                  MenuProps={customMenuProps}
                                  {...{
                                    readOnly:
                                      props && props.mode === 'view'
                                        ? true
                                        : field.readOnly ?? false,
                                  }}
                                >
                                  {field.fieldOptions.map((option: FieldDropdownOptions, index) => {
                                    return (
                                      <MenuItem key={index} value={option.value}>
                                        {`${option.optionLabel}`}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                                <FormHelperText>
                                  {field.parentFieldName
                                    ? errors[field.parentFieldName] &&
                                      errors[field.parentFieldName][field.fieldName] &&
                                      errors[field.parentFieldName][field.fieldName].message
                                    : errors[field.fieldName] && errors[field.fieldName].message}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                          );
                        case 'addressComponent':
                          let AddressComponent = field.component;
                          return (
                            <div key={field.fieldName} style={{ padding: 10 }}>
                              <AddressComponent
                                key={field.fieldName}
                                address={
                                  field.parentFieldName
                                    ? props.data[field.parentFieldName][field.fieldName]
                                    : props.data[field.fieldName] ?? ''
                                }
                                setAddress={props.customComponentFn}
                                readOnly={props && props.mode === 'view' ? true : false}
                              ></AddressComponent>
                            </div>
                          );
                        case 'paragraf':
                          return (
                            <Grid
                              key={`paragraf_${index}`}
                              item
                              xs={12}
                              sm={field.isAlone ? 12 : 6}
                              style={paragrafStyle}
                            >
                              {field.label}
                            </Grid>
                          );
                        case 'radioButtons':
                          return (
                            <Grid
                              key={`radioButtons_${index}`}
                              item
                              xs={12}
                              sm={field.isAlone ? 12 : 6}
                            >
                              <FormControl
                                error={
                                  field.parentFieldName
                                    ? errors[field.parentFieldName] &&
                                      errors[field.parentFieldName][field.fieldName]
                                      ? true
                                      : false
                                    : errors[field.fieldName]
                                    ? true
                                    : false
                                }
                              >
                                <RadioGroup
                                  row
                                  id={field.fieldName}
                                  name={
                                    field.parentFieldName ? field.validationName : field.fieldName
                                  }
                                  value={
                                    field.parentFieldName
                                      ? props.data[field.parentFieldName][field.fieldName]
                                      : props.data[field.fieldName] ?? ''
                                  }
                                  onChange={e => {
                                    props.updateDataFn(
                                      {
                                        target: {
                                          value: str2bool(e.target.value),
                                          name: e.target.name,
                                        },
                                      },
                                      field.parentFieldName ?? ''
                                    );
                                  }}
                                >
                                  <FormControlLabel
                                    {...(props && props.mode === 'view'
                                      ? null
                                      : {
                                          ...register(field.validationName, {
                                            ...validationsTypes(field),
                                          }),
                                        })}
                                    value={true}
                                    control={<Radio color="primary" />}
                                    label="Yes"
                                    disabled={
                                      props && props.mode === 'view'
                                        ? true
                                        : field.readOnly ?? false
                                    }
                                    sx={{
                                      '.MuiRadio-colorPrimary.Mui-disabled': {
                                        color: '#467495',
                                      },
                                    }}
                                  />
                                  <FormControlLabel
                                    {...(props && props.mode === 'view'
                                      ? null
                                      : {
                                          ...register(field.validationName, {
                                            ...validationsTypes(field),
                                          }),
                                        })}
                                    value={false}
                                    control={<Radio color="primary" />}
                                    label="No"
                                    disabled={
                                      props && props.mode === 'view'
                                        ? true
                                        : field.readOnly ?? false
                                    }
                                    sx={{
                                      '.MuiRadio-colorPrimary.Mui-disabled': {
                                        color: '#467495',
                                      },
                                    }}
                                  />
                                </RadioGroup>
                                <FormHelperText>
                                  {field.parentFieldName
                                    ? errors[field.parentFieldName] &&
                                      errors[field.parentFieldName][field.fieldName] &&
                                      errors[field.parentFieldName][field.fieldName].message
                                    : errors[field.fieldName] && errors[field.fieldName].message}
                                </FormHelperText>
                              </FormControl>
                            </Grid>
                          );
                        case 'upload':
                          return (
                            <Grid key={`upload_${index}`} item xs={12} sm={field.isAlone ? 12 : 6}>
                              <UploadComponent
                                {...{
                                  id: index,
                                  title: 'Upload',
                                  label: field.label,
                                  updateParentFn: props.updateDataFn,
                                }}
                              ></UploadComponent>
                            </Grid>
                          );
                        case 'component':
                          let Component = field.component;
                          return <Component key={`component_${index}`} {...props}></Component>;
                        default:
                          break;
                      }
                    })}
                </Grid>
              </div>
            );
          })}
      </form>
    </>
  );
};
