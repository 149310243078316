import { SelectChangeEvent } from '@mui/material';
import { ConfirmModalProps, MODAL_TYPES, ModalProps } from './GlobalModal';

/**
 * Service for creating and dispaching specific modal
 */
export class ModalService {
  private _showModal: any;
  private _hideModal: any;

  constructor(
    showModal: (modalType: string, modalProps?: ModalProps) => void,
    hideModal: () => void
  ) {
    this._showModal = showModal;
    this._hideModal = hideModal;
  }

  /**
   * Dispach predifined confirm modal
   * @param event
   * @param data
   * @param confirmFn function will trigger after confirming
   * @param _description modal description
   */
  public showConfirmModal(
    event: SelectChangeEvent<string>,
    data: any,
    confirmFn: Function,
    _description: string
  ) {
    this._showModal(MODAL_TYPES.CONFIRM_MODAL, {
      title: 'Confirm',
      description: _description,
      confirmBtn: 'Confirm',
      confirmFn: () => confirmFn(event, data),
      cancelBtn: 'Cancel',
      cancelFn: () => this._hideModal(),
      disableEscapeKeyDown: true,
      backdropClick: true,
    } as ConfirmModalProps);
  }

  /**
   *
   * @param _error
   * @param _message
   * @param _status
   */
  public showErrorModal(
    _error: string = 'Error',
    _message: string = 'Something went wrong!',
    _status: any = ''
  ) {
    this._showModal(MODAL_TYPES.ERROR_MODAL, {
      title: `${_error} ${_status}`,
      disableEscapeKeyDown: false,
      backdropClick: false,
      description: _message,
    });
  }

  public showSettlementModal(dto: any) {
    this._showModal(MODAL_TYPES.SETTLEMENT_MODAL, dto);
  }
}
