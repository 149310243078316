export class ClaimCreateDto {
  quoteId: number = 0;
  claimType: string = '';
  claimDescription: string = ''; // FE
  carrierId: string = '';
  typeOfQuote: string = 'ARBITRATION';
  total: string = '';
  reserve: string = ''; //
  fnolDate: string = ''; //
  policyNumber: string = '';
  claimDocuments: DocumentDto[] = []; //FE
  claimMessageDto: MessageDto = new MessageDto(); //FE
  public constructor(init?: Partial<ClaimCreateDto>) {
    Object.assign(this, init);
  }
}

export class DocumentDto {
  description: string = '';
  fileName: string = '';
  file?: any = {};
  public constructor(init?: Partial<DocumentDto>) {
    Object.assign(this, init);
  }
}

export class MessageDto {
  claimId: string = '';
  content: string = '';
  sentBy: string = '';
  sentAt: string = '';
  public constructor(init?: Partial<MessageDto>) {
    Object.assign(this, init);
  }
}
