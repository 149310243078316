import { authAxios } from './AuthService';

const API = `api/v1/reports`;
const ARBITRATION = 'ARBITRATION';

const searchReports = search => {
  return authAxios()
    .get(
      `${API}/quote-reports-search?sort=renewalDate,desc&searchFields=policyNumber,person.firstName,person.lastName,person.postCode,person.phone&searchValue=${search}&insuranceType=${ARBITRATION}`
    )
    .then(res => res);
};

const getReports = page => {
  return authAxios()
    .get(`${API}/all?page=${page}&sort=string&insuranceType=${ARBITRATION}`)
    .then(res => res);
};

export { searchReports, getReports };
