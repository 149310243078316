const str2bool = (value: string) => {
  if (value && typeof value === 'string') {
    if (value.toLowerCase() === 'true') return true;
    if (value.toLowerCase() === 'false') return false;
  }
  return value;
};

const generateKey = (prefix: string) => {
  return `${prefix}_${new Date().getTime()}`;
};

export { str2bool, generateKey };
