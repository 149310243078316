import * as React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, IconButton } from '@mui/material';
import Typography from '@material-ui/core/Typography';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddCardIcon from '@mui/icons-material/AddCard';
import { Button, Grid, styled, TextareaAutosize } from '@material-ui/core';
import { ClaimCreateDto, DocumentDto } from '../../models/ClaimCreateDto';
import { CloudUpload } from '@material-ui/icons';
import { generateKey } from '../../Utils';

export interface AttachmentsComponentProps {
  id: string | number;
  data: DocumentDto[];
  customComponentFn: Function;
}

export function AttachmentsComponent(props: AttachmentsComponentProps) {
  /**
   * TODO for now work with specific Dto
   * Add remove accordion
   * @param event
   * @param index
   */
  const handleAccordion = (event: any, index: number | undefined = undefined) => {
    if (index !== undefined) {
      props.data.splice(index, 1);
      props.customComponentFn((prevState: ClaimCreateDto) => ({
        ...prevState,
        ['claimDocuments']: [...props.data],
      }));
    } else {
      props.customComponentFn((prevState: ClaimCreateDto) => ({
        ...prevState,
        ['claimDocuments']: [...props.data, new DocumentDto()],
      }));
    }
  };

  /**
   * TODO refactor
   */
  const handleFile = ({ target: { files, name } }: any, index: number) => {
    const id = generateKey(files[0].name);
    props.customComponentFn((prevState: any) => {
      let newState = { ...prevState };
      newState.claimDocuments[index].fileName = files[0].name;
      newState.claimDocuments[index].file = { file: files[0], id: id, description: name };
      return newState;
    });
  };

  const Input = styled('input')({
    display: 'none',
  });
  const dots = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  };
  const infoWrapper = {
    display: 'flex',
    flexDirection: 'column',
    justifYcontent: 'center',
    alignItems: 'stretch',
    padding: '10px',
    rowGap: '5px',
  };

  return (
    <div key={`${props.id}`} style={{ width: '100%', padding: '10px' }}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          marginBottom: '10px',
          flexDirection: 'row',
          fontSize: '1.1em',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div key={`wrap-${props.id}`}>Please supply other supporting documentation</div>
        <Button
          key={`button_${props.id}`}
          variant="contained"
          size="small"
          color="primary"
          onClick={e => handleAccordion(e)}
          startIcon={<AddCardIcon />}
        >
          Add
        </Button>
      </div>
      {props.data.map((_item: any, index: number) => {
        return (
          <Accordion key={`Accordion-${index}`}>
            <AccordionSummary
              key={`Panel-${index}`}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id={`Panel-${index}`}
              sx={{
                backgroundColor: 'whitesmoke',
              }}
            >
              <Typography style={{ flex: 1 }}>{`Attachment ${index + 1}`}</Typography>
              <IconButton
                id={`icon-${index}`}
                sx={{ padding: 'inherit', marginRight: '10px' }}
                aria-label="delete"
                onClick={e => handleAccordion(e, index)}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>{`File description (optional)`}</Grid>
                <Grid item xs={12} sm={6}>
                  <TextareaAutosize
                    name="description"
                    id="description"
                    value={props.data[index].description ?? ''}
                    minRows={6}
                    style={{
                      width: '100%',
                      maxWidth: '100%',
                      fontFamily: 'inherit',
                      fontSize: 'inherit',
                    }}
                    onChange={e => {
                      props.customComponentFn((prevState: any) => {
                        let newState = { ...prevState };
                        newState.claimDocuments[index].description = e.target.value;
                        return newState;
                      });
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >{`Attach any documentary evidence here (images, pdfs and documents only)`}</Grid>
                <Grid item xs={12} sm={6}>
                  <label htmlFor={`contained-button-file-${index}-${props.id}`}>
                    <Input
                      id={`contained-button-file-${index}-${props.id}`}
                      name={`contained-button-file-${index}-${props.id}`}
                      key={`contained-button-file-${index}-${props.id}`}
                      type="file"
                      hidden
                      style={{ display: 'none' }}
                      onChange={e => handleFile(e, index)}
                    />
                    <Button
                      variant="contained"
                      component="span"
                      color="primary"
                      id={`contained-button-visible-file-${index}-${props.id}`}
                      key={`contained-button-visible-file-${index}-${props.id}`}
                      size="medium"
                      startIcon={<CloudUpload />}
                    >
                      Attach File
                    </Button>
                  </label>
                  {props.data.length && props.data[index] ? (
                    <div
                      style={{ ...(infoWrapper as any) }}
                      key={`div-wrapper-file-${index}-${props.id}`}
                    >
                      <span style={{ color: '#467495' }}>Filename :</span>
                      <div style={{ ...dots } as any}>
                        {`${props.data[index].file?.file?.name ?? ''}`}
                      </div>

                      <span style={{ color: '#467495' }}>Filestype :</span>
                      <div style={{ ...dots } as any}>
                        {`${props.data[index].file?.file?.type ?? ''}`}
                      </div>

                      <span style={{ color: '#467495' }}>Size in bytes :</span>
                      <div style={{ ...dots } as any}>
                        {`${props.data[index].file?.file?.size ?? ''}`}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}
