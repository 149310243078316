import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { AuthContextProvider } from "./store/auth-context";
import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
if (localStorage.getItem("token")) {
  axios.interceptors.request.use(function (config) {
    const token = window.localStorage.getItem("token");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });
}

ReactDOM.render(
  <AuthContextProvider>
    <App />
  </AuthContextProvider>,
  document.getElementById("root")
);
