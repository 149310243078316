import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { isValid } from 'postcode';
import getAddressByPostCode from '../services/AddressService';
import AddressComponentStyle from './AddressComponentStyle';
import CircularProgress from '@mui/material/CircularProgress';

const AddressComponent = ({ address, setAddress, readOnly = false }) => {
  const classes = AddressComponentStyle();
  const [newAddress, setNewAddress] = useState({ ...address });
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [postalCodeData, setPostalCodeData] = useState([]);
  const [startValidationFn, setStartValidationFn] = useState(false);

  const handleChangeAddress = event => {
    setAddress({ ...address, [event.target.name]: event.target.value });
    setNewAddress(prevState => ({ ...prevState, [event.target.name]: event.target.value }));
  };

  const capitalizeFirst = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  // Commented to fix populating address fields with driver proposer function ==> Testers should confirm if everything is ok
  // useEffect(() => {
  //   setAddress(newAddress);
  // }, [newAddress]);

  useEffect(() => {
    setNewAddress({ ...address });
  }, [address]);

  useEffect(() => {
    setLoading(true);
    isValid(newAddress.postCode) || newAddress.postCode === ''
      ? setStartValidationFn(false)
      : setStartValidationFn(true);
    if (isValid(newAddress.postCode) && startValidationFn) {
      getAndMapPostalCodeData(newAddress.postCode);
    } else {
      setOpen(false);
      setPostalCodeData([]);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [newAddress.postCode]);

  useEffect(() => {
    if (postalCodeData.length > 0) {
      setOpen(true);
    }
  }, [postalCodeData]);

  function getAndMapPostalCodeData(validPostCode) {
    getAddressByPostCode(validPostCode)
      .then(res => {
        const postcodeAddress = res?.data?.addresses.map((addr, index) => {
          return {
            id: index,
            flatNo: '',
            address1: addr?.formatted_address[0] ? addr.formatted_address[0] : '',
            address2: addr?.formatted_address[1] ? addr.formatted_address[1] : '',
            address3: addr?.formatted_address[2] ? addr.formatted_address[2] : '',
            address4: addr?.formatted_address[3] ? addr.formatted_address[3] : '',
            address5: addr?.formatted_address[4] ? addr.formatted_address[4] : '',
            postCode: validPostCode,
            label:
              addr.formatted_address
                .slice(', ')
                .filter(el => el)
                .join(', ') +
              ', ' +
              validPostCode.toUpperCase(),
          };
        });
        setPostalCodeData([...postcodeAddress]);
        setStartValidationFn(false);
        setOpen(true);
      })
      .catch(err => {
        setNewAddress({ ...newAddress, postCode: '' });
        alert(err.message);
      });
  }

  function handleSelectedAddress(address) {
    setNewAddress({ ...address });
    setOpen(false);
    setAddress(address);
  }

  const handlePostcodeFocus = () => {
    if (isValid(newAddress.postCode)) {
      getAndMapPostalCodeData(newAddress.postCode);
    }
    if (postalCodeData.length > 0) setOpen(true);
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          label="Postcode"
          variant="standard"
          id="postCode"
          fullWidth
          name="postCode"
          value={newAddress.postCode}
          error={startValidationFn}
          onFocus={handlePostcodeFocus}
          onChange={handleChangeAddress}
          inputProps={{ style: { textTransform: 'uppercase' } }}
          InputProps={{
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
              </React.Fragment>
            ),
            readOnly: readOnly,
          }}
        />
        {open &&
          !readOnly &&
          (postalCodeData.length > 0 ? (
            <ul className={classes.listSelect}>
              {postalCodeData.map((address, index) => {
                return (
                  <li key={index} onClick={() => handleSelectedAddress(address)}>
                    <div className={classes.listItem}>{address.label}</div>
                  </li>
                );
              })}
            </ul>
          ) : (
            ''
          ))}
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          id="flatNo"
          name="flatNo"
          label="Flat number"
          fullWidth
          variant="standard"
          value={newAddress.flatNo}
          onChange={handleChangeAddress}
          InputProps={{
            readOnly: readOnly,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          id="address1"
          name="address1"
          label="House number and street"
          fullWidth
          variant="standard"
          value={newAddress.address1}
          onChange={handleChangeAddress}
          InputProps={{
            readOnly: readOnly,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          id="address2"
          name="address2"
          label="Address 2"
          fullWidth
          variant="standard"
          value={capitalizeFirst(newAddress.address2)}
          onChange={handleChangeAddress}
          InputProps={{
            readOnly: readOnly,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          id="address3"
          name="address3"
          label="Address 3"
          fullWidth
          variant="standard"
          value={capitalizeFirst(newAddress.address3)}
          onChange={handleChangeAddress}
          InputProps={{
            readOnly: readOnly,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          id="address4"
          name="address4"
          label="City/Town"
          fullWidth
          variant="standard"
          value={capitalizeFirst(newAddress.address4)}
          onChange={handleChangeAddress}
          InputProps={{
            readOnly: readOnly,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          id="address5"
          name="address5"
          label="Country"
          fullWidth
          variant="standard"
          value={capitalizeFirst(newAddress.address5)}
          onChange={handleChangeAddress}
          InputProps={{
            readOnly: readOnly,
          }}
        />
      </Grid>
    </Grid>
  );
};
export default AddressComponent;
