import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useLocation, useNavigate } from 'react-router-dom';
import { activateRegistration } from '../services/AuthService';
import { set } from 'react-hook-form';

const ActivateUser = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [activationKey, setActivationKey] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordMatchError, setPasswordMatchError] = useState('');
  const [passwordScore, setPasswordScore] = useState('');
  const { search } = useLocation();

  useEffect(() => {
    setActivationKey(search.split('=')[1]);
  }, [setActivationKey, search]);

  const handleSubmit = e => {
    e.preventDefault();

    if (password === confirmPassword && passwordScore > 1) {
      activateRegistration(password, activationKey).then(res => {
        if (res.status === 200) {
          alert('Password has been set successfully');
          navigate('/login');
        }
      });
    }

    if (password !== confirmPassword) {
      setPasswordMatchError("Passwords doesn't match");
    } else {
      setPasswordMatchError('');
    }

    if (passwordScore <= 1) {
      setPasswordError('Password is not secure');
    } else {
      setPasswordError('');
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom component="h1">
        Activate User
      </Typography>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <TextField
          type="hidden"
          id="activationKey"
          name="activationKey"
          value={activationKey}
          autoFocus
        />
        <TextField
          error={!!passwordError}
          helperText={passwordError}
          onChange={e => setPassword(e.target.value)}
          variant="outlined"
          margin="normal"
          type="password"
          required
          fullWidth
          id="password"
          label="Password"
          name="password"
          value={password}
          autoFocus
        />
        <PasswordStrengthBar
          onChangeScore={(score, feedback) => {
            setPasswordScore(score);
            console.log(score);
          }}
          password={password}
        />
        <TextField
          error={!!passwordMatchError}
          helperText={passwordMatchError}
          onChange={e => setConfirmPassword(e.target.value)}
          variant="outlined"
          margin="normal"
          type="password"
          required
          fullWidth
          name="confirmPassword"
          label="Confirm Password"
          id="confirmPassword"
          value={confirmPassword}
        />
        <Button type="submit" fullWidth variant="contained" color="primary">
          Save Password
        </Button>
      </form>
    </Container>
  );
};

export default ActivateUser;
