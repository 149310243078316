import { renderCellExpand } from './DataGridExpandCell';

/**
 * DATA_GRID COLUMNs HELPER
 */
export class DataGridColumnService {
  private _formatter!: any;

  constructor(private currencyType: string) {
    this._formatter = formatter(this.currencyType);
  }
  /**
   * create DataGrid MUI Columns
   *
   * Array of objects witch represent MUI Columns Definitions
   * @param _displayedColumns
   *
   */
  public createCustomTableColumns(_displayedColumns: any[], _headerClassName?: string): any[] {
    const columns = _displayedColumns.map((displayedColDef: any) => ({
      ...displayedColDef,
      headerClassName: _headerClassName,
      headerName: displayedColDef.headerName
        ? displayedColDef.headerName
        : this._transformCamelCase(displayedColDef.field),
      valueFormatter: (params: any) => this._valueFormatter(displayedColDef, params),
      renderCell: displayedColDef.isCellExpand
        ? (params: any) => this._renderExpandCell(displayedColDef, params)
        : null,
    }));
    return columns;
  }
  /**
   * Impl of render cell expand function DataGridExpandCell
   *
   * override props with displayedColDef passed from component
   * @param displayedColDef
   */
  private _renderExpandCell(displayedColDef: any, params: any) {
    params.value = this._valueFormatter(displayedColDef, params);
    return renderCellExpand(params);
  }

  private _valueFormatter = (displayedColDef: any, params: any) => {
    if (params.value === null) {
      return '';
    }
    if (displayedColDef.currency) {
      return this._columnCurrencyValueFormatter(params);
    }
    if (displayedColDef.isToUpperCase) {
      return params?.value?.toUpperCase();
    }
    //TODO GEN FOR ALL OBJECTS
    if (displayedColDef.object) {
      return params.value.name;
    }
    return params.value;
  };

  private _columnCurrencyValueFormatter = (params: any) => {
    if (params.value === null) {
      return '';
    }
    const valueFormatted = this._formatter.format(params.value);
    return valueFormatted;
  };

  private _transformCamelCase(camelCase: string) {
    const result = camelCase.replace(/(?=[A-Z])|(?<=[a-z])(?=\d)/g, ' ');
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  }
}

/**
 * CURRENCY FORMATTER HELPER
 * These options are needed to round to whole numbers if that's what you want.
 * minimumFractionDigits: 0, //  2500.10 => $2,500.1)
 * maximumFractionDigits: 0, //  2500.99 => $2,501)
 */
export function formatter(currencyType: string, options?: any) {
  switch (currencyType) {
    case 'GBP':
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'GBP',
      });
    case 'USD':
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    case 'EUR':
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'EUR',
      });
    default:
      // Default will be GBP
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'GBP',
      });
  }
}
