import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, CssBaseline, Grid } from '@material-ui/core';
import { ArbitrationInsurance } from './ArbitrationInsurance';
import { useParams } from 'react-router-dom';

export type pageMode = 'edit' | 'view' | null;

const useStyles = makeStyles({
  logo: {
    flexGrow: 1,
  },
  media: {
    height: 200,
  },
  imageLogo: {
    width: 110,
    marginTop: 8,
  },
  topMargin: {
    marginTop: 8,
  },
});
export const WrapperPage = () => {
  const classes = useStyles();
  const { id } = useParams();

  return (
    <>
      <CssBaseline />
      <Container maxWidth="md" className={classes.topMargin}>
        <Grid item lg={12}>
          <ArbitrationInsurance
            {...{ title: 'ARBITRATION', mode: id ? 'view' : null, id: id ? id : null }}
          ></ArbitrationInsurance>
        </Grid>
      </Container>
    </>
  );
};

export default WrapperPage;
