import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Tooltip,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DownloadingSharpIcon from '@mui/icons-material/DownloadingSharp';
import IconButton from '@mui/material/IconButton';
import DownloadService from '../../services/DownloadService';

type FileListComponentProps = {
  data: any;
};

export default function FileListComponent(props?: FileListComponentProps): JSX.Element {
  const { data } = props;
  console.log(data?.documentDtoList);

  const handleDownload = (file: { base64: string; fileName: string; fileType: string }): void => {
    DownloadService.downloadFile(file);
  };

  return (
    <div style={{ margin: '1rem', width: '100%' }}>
      {data?.arbitrationQuoteId && (
        <>
          <Accordion style={{ background: '#fbfbfb', marginBottom: '15px' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: '#467495' }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{ color: '#467495' }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CloudDownloadIcon sx={{ mr: 4 }} />
                <Typography component={'div'} variant="h5">
                  Your Documents and Attachments
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails
              sx={{ display: 'grid', rowGap: '20px', alignContent: 'center', scale: '0.9' }}
            >
              {data?.documentDtoList.length ? (
                data?.documentDtoList.map(
                  (file: { base64: string; fileName: string; fileType: string }, index: number) => (
                    <Container
                      key={`${index}_list_item_key`}
                      style={{
                        display: 'flex',
                        borderBottom: 'thin #467495 solid',
                        padding: '0 0 5px 0',
                        alignItems: 'center',
                      }}
                    >
                      <div style={{ flex: 5, fontWeight: 'bold' }}>{file.fileName}</div>
                      <div
                        style={{
                          flex: 1,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'end',
                        }}
                      >
                        <Tooltip title="Download">
                          <IconButton aria-label="download" onClick={() => handleDownload(file)}>
                            <DownloadingSharpIcon sx={{ color: '#467495' }} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </Container>
                  )
                )
              ) : (
                <Container
                  style={{
                    display: 'flex',
                    borderBottom: 'thin #467495 solid',
                    padding: '0 0 5px 0',
                  }}
                >
                  <div
                    style={{ flex: 1, fontWeight: 'bold', color: '#467495', textAlign: 'center' }}
                  >
                    No documents and attachments.
                  </div>
                </Container>
              )}
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </div>
  );
}
