import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@material-ui/core/Button';
import { useGlobalModalContext } from './GlobalModal';
import { DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { modalStyles, dialogTitleStyle, buttonStyle, dialogContentStyle } from './ModalStyle';

export const ConfirmModal = () => {
  const style = modalStyles('#467495', 'white', 'white');
  const dialogTitleStyles = dialogTitleStyle('#467495');
  const buttonStyles = buttonStyle('#467495', 'white');
  const dialogContentStyles = dialogContentStyle('#467495');

  const { hideModal, store } = useGlobalModalContext();
  const { modalProps } = store || {};
  const {
    title,
    confirmBtn,
    confirmFn,
    disableEscapeKeyDown,
    backdropClick,
    cancelBtn,
    cancelFn,
    description,
  } = modalProps || {};

  const handleModalToggle = () => {
    hideModal();
  };

  return (
    <Modal
      disableEscapeKeyDown={disableEscapeKeyDown}
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={(_, reason) => {
        if (reason !== 'backdropClick' && backdropClick) {
          handleModalToggle();
        }
      }}
    >
      <Box sx={style}>
        <DialogTitle style={{ ...dialogTitleStyles }} id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent style={{ ...dialogContentStyles }}>
          <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {cancelBtn && (
            <Button style={{ ...buttonStyles }} onClick={cancelFn}>
              {cancelBtn}
            </Button>
          )}
          {confirmBtn && confirmFn && (
            <Button style={{ ...buttonStyles }} onClick={confirmFn} autoFocus>
              {confirmBtn}
            </Button>
          )}
        </DialogActions>
      </Box>
    </Modal>
  );
};
