import React, { useState, useContext } from 'react';
import AuthContext from '../store/auth-context';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@mui/icons-material/Person';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const UserDrop = props => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [name, setName] = useState('');
  const [name1, setName1] = useState('');

  //const [logUser, setLogUser] = useState("");

  const authCtx = useContext(AuthContext);

  const user = authCtx.user;

  //setLogUser(user?.user?.firstName);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    // setAnchorEl1(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    // setAnchorEl1(null);
  };

  const handleClick1 = event => {
    setAnchorEl1(event.currentTarget);
    // setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const logoutHandler = () => {
    authCtx.logout();
  };

  let storage = localStorage.getItem('token');

  return (
    <div>
      <div style={{ display: 'inline-block' }}>
        <Button
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="contained"
          color="primary"
          onClick={handleClick}
        >
          {props.name === 'Personal' ? (
            <ListItemIcon>
              <PersonIcon fontSize="medium" />
            </ListItemIcon>
          ) : (
            <ListItemIcon>
              <SettingsApplicationsIcon fontSize="medium" />
            </ListItemIcon>
          )}
          {props.name}
        </Button>
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {storage !== null && props.route && (
            <StyledMenuItem>
              <ListItemIcon>{/* <SendIcon fontSize="small" /> */}</ListItemIcon>
              <Link to={props.route}>
                <ListItemText primary={props.text} />
              </Link>
            </StyledMenuItem>
          )}
          {storage !== null && props.route1 && (
            <StyledMenuItem>
              <ListItemIcon>{/* <SendIcon fontSize="small" /> */}</ListItemIcon>
              <Link to={props.route1}>
                <ListItemText primary={props.text1} />
              </Link>
            </StyledMenuItem>
          )}
          {storage !== null && props.route2 && (
            <StyledMenuItem>
              <ListItemIcon>{/* <SendIcon fontSize="small" /> */}</ListItemIcon>
              <Link to={props.route2}>
                <ListItemText primary={props.text2} />
              </Link>
            </StyledMenuItem>
          )}
          {storage !== null && props.route3 && (
            <StyledMenuItem>
              <ListItemIcon>{/* <SendIcon fontSize="small" /> */}</ListItemIcon>
              <Link to={props.route3}>
                <ListItemText primary={props.text3} />
              </Link>
            </StyledMenuItem>
          )}
          {props.name === 'Personal' ? (
            <StyledMenuItem>
              <ListItemIcon>
                <ExitToAppIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Logout" onClick={logoutHandler} />
            </StyledMenuItem>
          ) : null}
        </StyledMenu>
      </div>
    </div>
  );
};

export default UserDrop;
