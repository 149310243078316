import React, { createContext, useContext, useState } from 'react';
import { ConfirmModal } from './ConfirmModal';
import { ErrorModal } from './ErrorModal';
import SettlementModal from './Settlement Modal';

/**
 * Base modal props
 */
export interface ModalProps {
  title: string;
  description: string;
  disableEscapeKeyDown: boolean;
  backdropClick: boolean;
}

/**
 * Specific modal props
 */
export interface ConfirmModalProps extends ModalProps {
  confirmBtn: string;
  cancelBtn: string;
  confirmFn: Function;
  cancelFn: Function;
}

/**
 * Modal types
 */
export class MODAL_TYPES {
  public static readonly CONFIRM_MODAL: string = 'CONFIRM_MODAL';
  public static readonly ERROR_MODAL: string = 'ERROR_MODAL';
  public static readonly SETTLEMENT_MODAL: string = 'SETTLEMENT_MODAL';
}

/**
 * Register modal component
 */
const MODAL_COMPONENTS: any = {
  [MODAL_TYPES.CONFIRM_MODAL]: ConfirmModal,
  [MODAL_TYPES.ERROR_MODAL]: ErrorModal,
  [MODAL_TYPES.SETTLEMENT_MODAL]: SettlementModal,
};

/**
 * Context
 */
type GlobalModalContext = {
  showModal: (modalType: string, modalProps?: any) => void;
  hideModal: () => void;
  store: { modalType: string; modalProps: any };
};

const initalState: GlobalModalContext = {
  showModal: () => {},
  hideModal: () => {},
  store: { modalType: '', modalProps: {} },
};

const GlobalModalContext = createContext(initalState);
export const useGlobalModalContext = () => useContext(GlobalModalContext);

export const GlobalModal: React.FC<{}> = ({ children }) => {
  const [store, setStore] = useState({ modalType: '', modalProps: {} });
  const { modalType, modalProps } = store || {};
  /**
   * Function to fill store and show modal
   * @param modalType
   * @param modalProps
   */
  const showModal = (modalType: string, modalProps: any = {}) => {
    setStore({
      ...store,
      modalType,
      modalProps,
    });
  };
  /**
   * Function to clear store and hide modal
   */
  const hideModal = () => {
    setStore({
      ...store,
      modalType: null,
      modalProps: {},
    });
  };
  /**
   * @returns rendered modal
   */
  const renderComponent = () => {
    const ModalComponent = MODAL_COMPONENTS[modalType];
    if (!modalType || !ModalComponent) {
      return null;
    }
    return <ModalComponent id="global-modal" {...modalProps} />;
  };

  return (
    <GlobalModalContext.Provider value={{ store, showModal, hideModal }}>
      {renderComponent()}
      {children}
    </GlobalModalContext.Provider>
  );
};
