import * as React from 'react';
import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  TextField,
  Typography,
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import { useStyles } from '../styles/PasswordRecoveryStyle';
import { useForm } from 'react-hook-form';
import { resetPassword } from '../services/AuthService';
import { useGlobalModalContext } from '../components/global-modal/GlobalModal';
import { ModalService } from '../components/global-modal/ModalService';
import CopyrightComponent from '../components/CopyrightComponent';
/**
 * Readonly class with form email metadata
 */
class FormEmail {
  public static readonly TITLE: string = `Recover Your Password`;
  public static readonly EMIAL_INFO: string = `Please enter your email to get a link for the new password`;
  public static readonly EMAIL_PATTERN: RegExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public static readonly EMAIL_REQUIRED_MSG: string = `Email is required !`;
  public static readonly EMAIL_PATTERN_MSG: string = `Not valid email address.`;
  public static readonly EMAIL_SUCCESS_MSG: string = `Password recovery link has been sent to your email.`;
}

export function PasswordRecovery() {
  const classes = useStyles();
  const [success, setSuccess] = React.useState<boolean>(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<any>();

  /**
   * Handle submit API reset password
   */
  const onSubmit = (e: any) => {
    setSuccess(false);
    resetPassword({ email: e.email })
      .then((res: any) => {
        setSuccess(true);
      })
      .catch((err: any) => {
        const { data } = err['response'];
        showModalService.showErrorModal('Not Found', data?.error, '404');
      });
  };

  /**
   * Modal service
   */
  const { showModal } = useGlobalModalContext();
  const { hideModal } = useGlobalModalContext();
  const showModalService = new ModalService(showModal, hideModal);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <AccountCircleIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {FormEmail.TITLE}
        </Typography>
        <p>{FormEmail.EMIAL_INFO}</p>
        <form
          className={classes.form}
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          autoComplete="off"
        >
          <TextField
            {...register('email', {
              required: FormEmail.EMAIL_REQUIRED_MSG,
              pattern: { value: FormEmail.EMAIL_PATTERN, message: FormEmail.EMAIL_PATTERN_MSG },
            })}
            onChange={e => setSuccess(false)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            error={errors['email'] ? true : false}
            helperText={errors['email'] && errors['email'].message}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Send
          </Button>
        </form>
      </div>
      {success && <Box className={classes.success}>{FormEmail.EMAIL_SUCCESS_MSG}</Box>}
      {!success && <Box className={classes.success}></Box>}
      <Box mt={8}>
        <CopyrightComponent></CopyrightComponent>
      </Box>
    </Container>
  );
}
