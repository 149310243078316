import * as React from 'react';
import { Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';

const CopyrightComponent: React.FunctionComponent = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        InsurForce
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};

export default CopyrightComponent;
