import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  root: {
    '& .super-app-theme--header': {
      backgroundColor: '#FAFAFA',
    },
    '& .MuiSvgIcon-root': {
      right: '75px',
    },
    '& .MuiDataGrid-cell': {
      textAlign: 'center',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      whiteSpace: 'pre-wrap',
      lineHeight: '20px',
    },
    '& .MuiDataGrid-menuIconButton': {
      display: 'none',
    },
  },
  root_ext: {
    height: 400,
    width: '95%',
    margin: '2rem auto',
  },
}));

const searchStyles = () => ({
  borderStyle: 'solid',
  borderColor: '#C4C4C4',
  borderWidth: '1px',
  borderRadius: '5px',
  height: '54px',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: '0px !important',
});

export { useStyles, searchStyles };
