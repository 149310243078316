import React from 'react';
import Pagination from '@mui/material/Pagination';
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import { paginatorStyles } from './DataGridStyle';

export function CustomPagination(props: any) {
  const paginatorStyle = paginatorStyles();
  const apiRef = useGridApiContext();
  const page = props.page;
  const pageCount = props.count;

  // handle page change
  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    apiRef.current.setPage(value);
    props.onPageChange(value - 1); // call the callback with the new page number
    console.log('page', value);
  };

  return (
    <Pagination
      count={pageCount}
      page={page + 1}
      sx={{
        ...paginatorStyle,
      }}
      variant="outlined"
      shape="rounded"
      onChange={handlePageChange}
    />
  );
}
