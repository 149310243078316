import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@material-ui/core/Button';
import { useGlobalModalContext } from './GlobalModal';
import { DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { dialogTitleStyle, buttonStyle, dialogContentStyle, modalStyles } from './ModalStyle';

export const ErrorModal = () => {
  const style = modalStyles('red', 'white', 'white');
  const dialogTitleStyles = dialogTitleStyle('red');
  const buttonStyles = buttonStyle('white', 'red');
  const dialogContentStyles = dialogContentStyle('white');

  const { hideModal, store } = useGlobalModalContext();
  const { modalProps } = store || {};
  const { title, disableEscapeKeyDown, backdropClick, description } = modalProps || {};

  const handleModalToggle = () => {
    hideModal();
  };

  return (
    <Modal
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={(_, reason) => {
        if (
          (reason !== 'backdropClick' && !backdropClick) ||
          (reason !== 'escapeKeyDown' && !disableEscapeKeyDown)
        ) {
          handleModalToggle();
        }
      }}
    >
      <Box sx={style}>
        <DialogTitle style={{ ...dialogTitleStyles }} id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent style={{ ...dialogContentStyles }}>
          <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button style={{ ...buttonStyles }} autoFocus onClick={handleModalToggle}>
            Close
          </Button>
        </DialogActions>
      </Box>
    </Modal>
  );
};
