import axios from 'axios';

const getAddressByPostCode = (postCode) => {
    const API_URL = process.env.REACT_APP_API_URL;
    return axios({
        method: "get",
        url: `${API_URL}api/v1/postcode-lookup/${postCode}?expand=true&fuzzy=true`,
        headers: {
            "Content-Type": "application/json",
        },
    })
}
export default getAddressByPostCode