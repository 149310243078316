import { makeStyles } from '@material-ui/core/styles';

const AddressComponentStyle = makeStyles(theme => ({
  listSelect: {
    listStyleType: 'none',
    paddingLeft: 0,
    maxHeight: '17rem',
    overflow: 'auto',
    borderRight: '1px solid #ccc',
    borderLeft: '1px solid #ccc',
    borderBottom: '1px solid #ccc',
    borderTop: 0,
    position: 'absolute',
    backgroundColor: '#fff',
    zIndex: 2,
    width: '444px',
    borderRadius: '4px',
    '& li': {
      padding: '10px',
    },
    '& a': {
      textDecoration: 'none',
      color: '#000',
    },
    overflowY: 'auto',
    margin: 0,
    padding: 0,
    listStyle: 'none',
    height: '100%',
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey',
    },
  },
  listItem: {
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
    cursor: 'pointer',
  },
}));

export default AddressComponentStyle;
