import React, { useContext, useEffect } from 'react';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { Link, NavLink } from 'react-router-dom';
import UserDrop from './UserDrop';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { List, ListItem, ListItemText } from '@material-ui/core';
import AuthContext from '../store/auth-context';
import logo from '../images/Final-Logo-01-Cropped.png';
import { hasAclRoleName } from '../common/acl';
import './layout.css';

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  imageLogo: {
    width: 250,
    marginTop: 8,
  },
  navMenu: {
    display: 'flex',
  },

  logOut: {
    cursor: 'pointer',
  },
}));

const Layout = ({ children }) => {
  const classes = useStyles();
  const authCtx = useContext(AuthContext);

  useEffect(() => {});
  const menuId = 'primary-search-account-menu';
  const logoutHandler = () => {
    authCtx.logout();
  };

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            <Link to={'/arbitration'}>
              <img src={logo} className={classes.imageLogo} alt="Logo" />
            </Link>
          </Typography>
          <div className={classes.grow} />
          <List component="nav" className={classes.navMenu}>
            {getArbitrationMainMenu()}
            {authCtx.isLoggedIn ? (
              <ListItem>
                <ListItemText className={classes.logOut} primary="Logout" onClick={logoutHandler} />
              </ListItem>
            ) : (
              <ListItem button component={Link} to="/login">
                <ListItemText primary="Login" />
              </ListItem>
            )}
          </List>
        </Toolbar>
      </AppBar>
      {children}
    </div>
  );
};

const getArbitrationMainMenu = () => {
  return (
    <>
      {(hasAclRoleName('Administrator') || hasAclRoleName('Introducer')) && (
        <ListItem button component={NavLink} to="/arbitration">
          <ListItemText style={{ minWidth: '85px' }} primary="New Policy" />
        </ListItem>
      )}
      {(hasAclRoleName('Administrator') || hasAclRoleName('Introducer')) && (
        <ListItem button component={NavLink} to="/arbitration-policy" state={{ quoteType: 1 }}>
          <ListItemText primary="Policies" />
        </ListItem>
      )}
      {(hasAclRoleName('Administrator') || hasAclRoleName('Introducer')) && (
        <ListItem button component={NavLink} to="/quotes" state={{ quoteType: 0 }}>
          <ListItemText primary="Quotes" />
        </ListItem>
      )}
      {/*{(hasAclRoleName('Administrator') || hasAclRoleName('Introducer')) && (*/}
      {/*  <ListItem button component={NavLink} to="/claims">*/}
      {/*    <ListItemText primary="Claims" />*/}
      {/*  </ListItem>*/}
      {/*)}*/}
      {hasAclRoleName('Administrator') && (
        <ListItem button component={NavLink} to="/reports">
          <ListItemText primary="Reports" />
        </ListItem>
      )}
      {hasAclRoleName('Administrator') && (
        <ListItem>
          <UserDrop
            name={'Admin'}
            text={'Users'}
            route={'/users'}
            route3={'/admin'}
            text3={'Roles'}
          />
        </ListItem>
      )}
    </>
  );
};

export default Layout;
