import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import setAuthToken from '../common/setAuthToken';
import Typography from '@material-ui/core/Typography';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControlLabel, IconButton } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import GroupIcon from '@material-ui/icons/Group';
import AuthContext from '../store/auth-context';
import CloseIcon from '@material-ui/icons/Close';
import ListItemText from '@mui/material/ListItemText';
// accordion
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// accordion END

import { dataGridStyles } from './data-grid/DataGridStyle';
import { useStyles } from '../styles/DashStyle';
import { DataGridColumnService } from './data-grid/DataGridHelperService';
import { CustomPagination } from './data-grid/CustomPaginator';
import Title from './Title';
import { generateKey } from '../arbitration-insurance/Utils';
import { hasAclRoleName } from '../common/acl';

const columnService = new DataGridColumnService('GBP');
const columnsDefs = columnService.createCustomTableColumns(
  [
    { field: 'id', headerName: 'ID', flex: 0.2 },
    { field: 'name', flex: 1 },
    { field: 'description', flex: 1 },
  ],
  'super-app-theme--header'
);

/**
 * Old code from Sof/Nation need refactor
 */
const Dash = () => {
  const classes = useStyles();
  const dataGridStyle = dataGridStyles();

  const [open, setOpen] = useState(false);
  const [openPermission, setOpenPermission] = useState(false);
  const [description, setDescription] = useState('');
  const [name, setName] = useState('');
  const [rowDetails, setRowDetails] = useState([]);
  const [modules, setModules] = useState([]);

  const [checked, setChecked] = useState(false);

  const authCtx = useContext(AuthContext);

  const [roles, setRoles] = useState({});
  const [aclpermissions, setAclPermissions] = useState({});
  const [expanded, setExpanded] = useState(false);

  // accordion function
  const handleChangeAccordion = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // accrodion function END

  useEffect(() => {
    setAuthToken()
      .get('v2/role/all?page=0&limit=50')
      .then(res => {
        setRoles(res);
      })
      .catch(err => console.log(err));
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenPermission = () => {
    setOpenPermission(true);
  };

  const handleClosePermission = () => {
    setOpenPermission(false);
  };

  const handleSubmit = e => {
    e.preventDefault();

    axios({
      method: 'post',
      url: 'v2/role/addRole',
      data: {
        description,
        name,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        roles.data.content.push(res.data);
        setOpen(false);
      })
      .catch(err => {
        alert(err.message);
      });
  };

  const handleCheckboxChange = e => {
    if (e.target.checked) {
      axios
        .post(`v2/role/singleAclPermissionRole/${rowDetails.id}`, {
          aclPermissionId: e.target.value,
        })
        .then(res => {
          console.log(res);
          setRowDetails(prevState => ({
            ...prevState,
            aclPermissionSet: [...res.data.aclPermissionSet],
          }));
          setChecked(prevState => !checked);
        });
    } else {
      roles.data.content[rowDetails.id].aclPermissionSet.splice(1, 1);
      // skine permisiju za rolu
      axios
        .delete(`v2/role/singleAclPermissionRole/${rowDetails.id}`, {
          data: {
            aclPermissionId: e.target.value,
          },
        })
        .then(res => {
          setRowDetails(prevState => ({
            ...prevState,
            aclPermissionSet: [...res.data.aclPermissionSet],
          }));
          setChecked(prevState => !checked);
        });
    }
  };

  const actionsDefs = {
    field: 'permissions',
    headerName: 'Permissions',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'super-app-theme--header',
    sortable: false,
    disableClickEventBubbling: true,
    renderCell: params => {
      return (
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ cursor: 'pointer' }}
        >
          <EditPermissions index={params.row} />
        </div>
      );
    },
  };

  const EditPermissions = ({ index }) => {
    const handleEditPermission = () => {
      console.log(index);
      handleOpenPermission();
      setRowDetails(index);
    };

    return (
      <FormControlLabel
        control={
          <IconButton color="secondary" aria-label="edit user" onClick={handleEditPermission}>
            <GroupIcon style={{ color: blue[700] }} />
          </IconButton>
        }
      />
    );
  };

  const moduleMap = modules?.data?.content ? modules.data.content : [];

  const rows = roles?.data?.content
    ? roles.data.content.map(role => {
        return role;
      })
    : [];

  return (
    <div className={classes.grow}>
      <div className={[classes.root, classes.root_ext].join(' ')}>
        <Title>Admin roles</Title>
        <DataGrid
          disableColumnMenu
          autoHeight={true}
          rows={rows}
          columns={[...columnsDefs, actionsDefs]}
          sx={{ ...dataGridStyle }}
        />

        <div style={{ textAlign: 'right', marginTop: '2rem' }}>
          {hasAclRoleName('Administrator') && (
            <Button type="button" onClick={handleOpen} color="primary" variant="contained">
              Add Role
            </Button>
          )}
        </div>
        <Modal
          aria-labelledby="transition-modal-addRole"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper} key={Math.random()}>
              <IconButton
                className={classes.modalHeaderClose}
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
              <Typography variant="h3" gutterBottom component="h2" id="transition-modal-addRole">
                Add Role
              </Typography>
              <form className={classes.form} onSubmit={handleSubmit} noValidate autoComplete="off">
                <TextField
                  onChange={e => setDescription(e.target.value)}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="description"
                  label="Description"
                  name="description"
                  autoComplete="description"
                  autoFocus
                  //error={usernameError}
                />
                <TextField
                  onChange={e => setName(e.target.value)}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="name"
                  label="name"
                  type="name"
                  id="name"
                  autoComplete="current-password"
                  // error={passwordError}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Add
                </Button>
              </form>
            </div>
          </Fade>
        </Modal>
        <Modal
          aria-labelledby="transition-modal-editRole"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openPermission}
          onClose={handleClosePermission}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openPermission}>
            <div className={classes.paper}>
              <IconButton
                className={classes.modalHeaderClose}
                aria-label="close"
                color="inherit"
                size="medium"
                onClick={() => {
                  setOpenPermission(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
              <Typography variant="h4" gutterBottom component="h3" id="transition-modal-editRole">
                Edit Role for: {rowDetails.name}
              </Typography>
              {moduleMap.map((modul, index) => {
                const permissionModulss = modul?.aclPermissionSet
                  ? modul.aclPermissionSet.map(acl => {
                      return acl;
                    })
                  : [];
                return (
                  <div key={generateKey(`modal__${index}`)}>
                    <Accordion
                      className={classes.accordion}
                      expanded={expanded === modul.id}
                      onChange={handleChangeAccordion(modul.id)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id={modul.id}
                      >
                        <ListItemText
                          key={generateKey(`${modul.id}_list${index}`)}
                          primary={modul.name}
                        />
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          {permissionModulss.map(acl => {
                            return (
                              <ListItemText key={generateKey(`${acl.id}_list`)}>
                                <Checkbox
                                  checked={
                                    typeof rowDetails?.aclPermissionSet?.find(
                                      x => x.id === acl.id
                                    ) === 'object'
                                      ? true
                                      : false
                                  }
                                  onChange={handleCheckboxChange}
                                  inputProps={{
                                    'aria-label': 'primary checkbox',
                                  }}
                                  color="primary"
                                  name={acl.name}
                                  value={acl.id}
                                />
                                {acl.name} {acl.description}
                              </ListItemText>
                            );
                          })}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                );
              })}
            </div>
          </Fade>
        </Modal>
      </div>
    </div>
  );
};

export default Dash;
