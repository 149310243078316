import { authAxios } from './AuthService';

const getUsers = () => {
  return authAxios()
    .get(`v2/user/all?page=0&limit=50`)
    .then(res => {
      return res;
    })
    .catch(err => {
      console.log(err);
    });
};

export { getUsers };
