import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@material-ui/core';
import Layout from './components/Layout';
import Dash from '../src/admin/Dash';
import AclPermission from '../src/admin/AclPermission';
import { LoginPage } from './pages/LoginPage';
import Users from './pages/Users';
import ActivateUser from './pages/ActivateUser';
import ThankYou from './pages/ThankYou';
import ReportsArbitration from '../src/pages/ReportsArbitration';
import ClaimsArbitration from './pages/ClaimsArbitration';
import PrivateRoute from './common/PrivateRoute';
import { GlobalModal } from './components/global-modal/GlobalModal';
import { WrapperPage } from './arbitration-insurance/WrapperPage';
import { Quotes } from './pages/Quotes';
import { QuoteTypes } from './arbitration-insurance/models/ArbitrationModels';
import { generateKey } from './arbitration-insurance/Utils';
import { PasswordRecovery } from './pages/PasswordRecovery';
import UpdateDocuments from './pages/UpdateDocuments';

const theme = createTheme({
  palette: {
    primary: {
      light: '#f3f0e9',
      main: '#467495',
      dark: '#263b58',
      contrastText: '#fff',
    },
  },
  spacing: 8,
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <GlobalModal>
          <Layout>
            <Routes>{getArbitrationRoutes()}</Routes>
            {/* <Routes> <Route exact path="/" element={<Chart/>} /></Routes>*/}
          </Layout>
        </GlobalModal>
      </Router>
    </ThemeProvider>
  );
}

export default App;

const getArbitrationRoutes = () => {
  return (
    <>
      <Route exact path="/" element={<LoginPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/thank-you" element={<ThankYou />} />
      <Route exact path="/recover-pass" element={<PasswordRecovery />} />
      <Route exact path="/activateUser" element={<ActivateUser />} />
      <Route
        exact
        path="/arbitration"
        element={<PrivateRoute children={<WrapperPage key={generateKey('wrapper_page_1_')} />} />}
      />
      <Route
        exact
        path="/arbitration/:id"
        element={<PrivateRoute children={<WrapperPage key={generateKey('wrapper_page_2_')} />} />}
      />
      <Route
        exact
        path="/arbitration/:id/documents"
        element={<PrivateRoute children={<UpdateDocuments />} />}
      />
      <Route
        exact
        path="/arbitration-policy"
        element={<PrivateRoute children={<Quotes {...{ quoteType: QuoteTypes.LIVE_PAID }} />} />}
      />
      <Route
        exact
        path="/quotes"
        element={<PrivateRoute children={<Quotes {...{ quoteType: QuoteTypes.ACCEPTED }} />} />}
      />
      <Route exact path="/claims" element={<PrivateRoute children={<ClaimsArbitration />} />} />
      <Route exact path="/reports" element={<PrivateRoute children={<ReportsArbitration />} />} />
      <Route exact path="/acl-permission" element={<PrivateRoute children={<AclPermission />} />} />
      <Route exact path="/admin" element={<PrivateRoute children={<Dash />} />} />
      <Route exact path="/users" element={<PrivateRoute children={<Users />} />} />
      <Route
        exact
        path="/edit-claim/:claimId"
        element={<PrivateRoute children={<div>TODO</div>} />}
      />
    </>
  );
};
