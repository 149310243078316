const dataGridStyles = () => ({
  '& .MuiDataGrid-main': {
    border: '1px #467495 solid',
  },
  '& .MuiDataGrid-cell--textLeft': {
    borderBottom: '0.1px #467495 solid',
  },
  '& .MuiDataGrid-cell--textCenter': {
    borderBottom: '0.1px #467495 solid',
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#467495',
    fontSize: 12,
    '& .MuiDataGrid-columnHeader': {
      backgroundColor: 'transparent',
      color: 'white',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      whiteSpace: 'pre-wrap',
      lineHeight: '20px',
    },
    '& .MuiDataGrid-iconButtonContainer': {
      display: 'none',
    },
  },
  '& .MuiDataGrid-footerContainer': {
    borderBottom: '0.1px #467495 solid',
    borderRight: '0.1px #467495 solid',
    borderLeft: '0.1px #467495 solid',
    justifyContent: 'center',
  },
  // '& .MuiDataGrid-cell': {
  //   fontSize: 'inherit',
  //   whiteSpace: 'pre-wrap',
  //   lineHeight: '20px',
  //   display: 'block',
  // },
});

const dataGridWithoutFooterStyles = () => ({
  '& .MuiDataGrid-main': {
    border: '1px #467495 solid',
  },
  '& .MuiDataGrid-cell--textLeft': {
    borderBottom: '0.1px #467495 solid',
  },
  '& .MuiDataGrid-cell--textCenter': {
    borderBottom: '0.1px #467495 solid',
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#467495',
    fontSize: 12,
    minWidth: '300px',
    '& .MuiDataGrid-columnHeader': {
      backgroundColor: 'transparent',
      color: 'white',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      whiteSpace: 'pre-wrap',
      lineHeight: '20px',
    },
  },
  '& .MuiDataGrid-footerContainer': {
    borderBottom: '0.1px #467495 solid',
    borderRight: '0.1px #467495 solid',
    borderLeft: '0.1px #467495 solid',
    justifyContent: 'center',
    display: 'none',
  },
  // '& .MuiDataGrid-cell': {
  //   fontSize: 'inherit',
  //   whiteSpace: 'pre-wrap',
  //   lineHeight: '20px',
  //   display: 'block',
  // },
});

const paginatorStyles = () => ({
  '& .MuiButtonBase-root': {
    backgroundColor: '#9dbdd5',
    color: 'white',
  },
  '& .Mui-selected': {
    backgroundColor: '#467495 !important',
  },
  '& .MuiButtonBase-root:hover': {
    color: '#467495',
    backgroundColor: '#9dbdd5',
  },
});

const rowMenuListStyles = () => ({
  '& .css-6hp17o-MuiList-root-MuiMenu-list': {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'center',
  },
});

export { dataGridStyles, paginatorStyles, rowMenuListStyles, dataGridWithoutFooterStyles };
