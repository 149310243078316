class DownloadService {
  /**
   *
   * @param base64 {string} base64
   * @param fileName {string} Name of file
   * @param fileType {string} File type
   */
  public downloadFile({
    base64,
    fileName,
    fileType,
  }: {
    base64: string;
    fileName: string;
    fileType: string;
  }): void {
    const byteCharacters: string = atob(base64);
    const byteNumbers: any[] = new Array(byteCharacters.length);
    for (let i: number = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob: Blob = new Blob([byteArray], { type: fileType });
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }
}

export default new DownloadService();
