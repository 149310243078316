import axios from "axios";

const setAuthToken = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  if (localStorage.getItem("token")) {
    // Apply to every request
    //console.log(token);
    const api = axios.create({
      baseURL: `${API_URL}`,
      timeout: 1000,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return api;
  } else {
    // Delete auth header
    delete axios.defaults.headers.common["Authorization"];
    return "error";
  }
};

export default setAuthToken;
