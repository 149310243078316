import * as React from 'react';
import { Button, IconButton } from '@mui/material';
import { CloudUpload } from '@material-ui/icons';
import { styled } from '@mui/material/styles';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { generateKey } from '../../Utils';

export interface UploadComponentProps {
  id: number;
  title: string;
  label: string;
  updateParentFn: Function;
}

export interface AsFile {
  file: File;
  id: string;
  description: string;
}

export function UploadComponent(props: UploadComponentProps) {
  /**
   * State for local files
   */
  const [file, setFile] = React.useState<AsFile | null>(null);

  /**
   * Handle localy state
   */
  const handleFile = ({ target: { files, name } }: any) => {
    setFile({ file: files[0], id: generateKey(files[0].name), description: name });
  };

  /**
   * Effect to update State on parent
   */
  React.useEffect(() => {
    if (file !== null) {
      props.updateParentFn(file);
    }
  }, [file]);

  /**
   * Handle remove file localy and update parent state
   */
  const handleRemove = () => {
    props.updateParentFn(file, true);
    setFile(null);
  };

  const Input = styled('input')({
    display: 'none',
  });
  return (
    <div
      style={{
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        justifyContent: 'flex-start',
        alignContent: 'center',
      }}
    >
      <div style={{ minWidth: '20%' }}>{props.label}</div>
      <label htmlFor={`upload-contained-button-file-${props.id}`}>
        <Input
          id={`upload-contained-button-file-${props.id}`}
          key={`upload-contained-button-file-${props.id}`}
          name={`${props.label}`}
          type="file"
          hidden
          style={{ display: 'none' }}
          onChange={handleFile}
        />
        <Button
          variant="contained"
          key={`upload-contained-button-file-visible-${props.id}`}
          component="span"
          size="small"
          sx={{
            backgroundColor: '#467495',
            color: 'white',
            '&:hover': {
              backgroundColor: '#9dbdd5',
              color: '#467495',
            },
          }}
          startIcon={<CloudUpload />}
        >
          {props.title}
        </Button>
      </label>
      <p style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
        {file?.file?.name}
      </p>
      {file && (
        <IconButton
          size="large"
          sx={{
            height: '30px',
            width: '30px',
            padding: 'inherit',
            marginRight: '10px',
            backgroundColor: '#467495',
            color: 'white',
            '&:hover': {
              backgroundColor: '#9dbdd5',
              color: '#467495',
            },
          }}
          aria-label="delete"
          onClick={handleRemove}
        >
          <DeleteOutlineIcon />
        </IconButton>
      )}
    </div>
  );
}
