import React, { FunctionComponent, useState } from 'react';
import { Step, StepLabel, Stepper } from '@material-ui/core';
import { stepperStyle } from './StepperStyles';
import { Button, ButtonGroup } from '@material-ui/core';
import { StepProps } from '../../ArbitrationInsurance';
import { pageMode } from '../../WrapperPage';
import { useNavigate } from 'react-router-dom';

type StepperProps = {
  steps: StepProps[];
  getStepContent: Function;
  submitButtonIndex: number;
  submitButtonFn: Function;
  proceedButtonIndex: number;
  proceedButtonFn: Function;
  completeButtonIndex: number;
  completeButtonFn: Function;
  handleNextRef: any;
  mode: pageMode;
};

export const StepperComponent: FunctionComponent<StepperProps> = ({
  steps,
  getStepContent,
  submitButtonIndex,
  submitButtonFn,
  proceedButtonIndex,
  proceedButtonFn,
  completeButtonFn,
  completeButtonIndex,
  handleNextRef,
  mode,
}) => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const classes = stepperStyle();
  const navigate = useNavigate();

  React.useEffect(() => {
    handleNextRef.current = handleNext;
  }, []);
  const editFormRef: React.MutableRefObject<any> = React.useRef<any>(null);

  /**
   * Handle next with Form validation
   * @param activeStep Number of active step
   */
  function handleNext(activeStep: number) {
    if (editFormRef && editFormRef.current) {
      editFormRef.current.submitForm().then(() => {
        editFormRef.current.isValid() ? setActiveStep(activeStep + 1) : null;
      });
    }
  }
  /**
   * Handle submit with Form validation
   * @param activeStep Number of active step
   */
  function handleSubmitButtonFn(activeStep: number) {
    if (editFormRef && editFormRef.current) {
      editFormRef.current.submitForm().then(() => {
        editFormRef.current.isValid() ? submitButtonFn(activeStep) : null;
      });
    }
  }

  function handleBack(activeStep: number) {
    setActiveStep(activeStep - 1);
  }

  return (
    <>
      <Stepper activeStep={activeStep} alternativeLabel className={classes.stepperBox}>
        {steps.map((step: StepProps, index: number) => (
          <Step key={index}>
            <StepLabel>{step.title}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className={classes.content}>{getStepContent(activeStep, editFormRef)}</div>
      <div style={{ marginBottom: '3rem' }}>
        <ButtonGroup
          className={classes.stepperButtonGroup}
          variant="contained"
          aria-label="outlined primary button group"
        >
          <Button
            disabled={activeStep === 0}
            color={'primary'}
            onClick={() => handleBack(activeStep)}
          >
            Back
          </Button>
          {activeStep !== submitButtonIndex &&
            activeStep !== proceedButtonIndex &&
            activeStep !== completeButtonIndex && (
              <Button
                disabled={activeStep === steps.length - 1}
                color={'primary'}
                onClick={() => handleNext(activeStep)}
              >
                Next
              </Button>
            )}
          {activeStep === submitButtonIndex && (
            <Button color={'primary'} onClick={() => handleSubmitButtonFn(activeStep)}>
              {mode && mode === 'view' ? 'Next' : 'Submit'}
            </Button>
          )}
          {activeStep === proceedButtonIndex && (
            <Button
              color={'primary'}
              onClick={() =>
                mode && mode === 'view'
                  ? navigate('/arbitration-policy')
                  : proceedButtonFn(activeStep)
              }
            >
              {mode && mode === 'view' ? 'Return' : 'Proceed'}
            </Button>
          )}
          {activeStep === completeButtonIndex && (
            <Button color={'primary'} onClick={() => completeButtonFn(activeStep)}>
              Complete
            </Button>
          )}
        </ButtonGroup>
      </div>
    </>
  );
};
