import * as React from 'react';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { DataGrid } from '@mui/x-data-grid';

import { DataGridColumnService } from '../admin/data-grid/DataGridHelperService';
import { dataGridStyles } from '../admin/data-grid/DataGridStyle';
import Title from '../admin/Title';
import { CustomPagination } from '../admin/data-grid/CustomPaginator';
import { Button, buttonClasses } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useStyles } from '../styles/QuotesStyle';
import {
  approveQuote,
  getQuotes,
  reconcileQuote,
  searchQuotes,
  setDeclineStatusOnQuote,
} from '../services/QuotesService';
import { hasAclRoleName } from '../common/acl';
import { useGlobalModalContext } from '../components/global-modal/GlobalModal';
import { ModalService } from '../components/global-modal/ModalService';
import { QuoteTypes } from '../arbitration-insurance/models/ArbitrationModels';
import { useNavigate } from 'react-router-dom';

export interface IQuotes {
  quoteType: number;
}

const columnService = new DataGridColumnService('GBP');
let columnsDefs = columnService.createCustomTableColumns(
  [
    { field: 'policyNumber', flex: 1, maxWidth: 120 },
    { field: 'total', flex: 1, currency: true, maxWidth: 120 },
    { field: 'organisation', flex: 1 },
    { field: 'firstName', flex: 1 },
    { field: 'lastName', flex: 1 },
    { field: 'address1', flex: 1 },
    { field: 'address2', flex: 1 },
    { field: 'city', flex: 1 },
    { field: 'postCode', flex: 1, isToUpperCase: true },
    { field: 'phone', flex: 1, maxWidth: 120 },
  ],
  'super-app-theme--header'
);

const initialStateDataGrid = {
  sorting: { sortModel: [{ field: 'policyNumber', sort: 'desc' }] },
};

export function Quotes(props: IQuotes) {
  const [quoteType, setQuoteType] = React.useState<number | null>(null);

  const [quotes, setQuotes] = React.useState([]);
  const [search, setSearch] = React.useState<string | null>(null);

  // pagination
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const [pageNumber, setPageNumber] = React.useState<number>(0);

  const classes = useStyles();
  const dataGridStyle = dataGridStyles();
  const mountedRef = React.useRef(true);
  const navigate = useNavigate();

  const { showModal } = useGlobalModalContext();
  const { hideModal } = useGlobalModalContext();
  const showModalService = new ModalService(showModal, hideModal);

  /**
   * Handle action click
   * @param value row value from grid
   */
  const handleReview = (value: any) => {
    navigate(`/arbitration/${value?.row?.surveyorsArbitrationQuoteId}`);
  };

  const handleUpdateDocument = (value: any) => {
    navigate(`/arbitration/${value?.row?.surveyorsArbitrationQuoteId}/documents`);
  };

  /**
   * Function to change status of Quote
   * @param event event listener
   * @param value row value from grid
   */
  const handleStatusChange = (event: any, value: any): void => {
    switch (event.target.name) {
      case 'Reconcile':
        reconcileQuote(value?.id)
          .then(() => {
            refreshQuotes();
          })
          .catch((err: any) => console.log(err));
        break;
      case 'Decline':
        setDeclineStatusOnQuote(value?.id)
          .then(() => {
            refreshQuotes();
          })
          .catch((err: any) => console.log(err));
        break;
      case 'Approve':
        approveQuote(value?.id)
          .then(() => {
            refreshQuotes();
          })
          .catch((err: any) => console.log(err));
        break;
      default:
        throw new Error('No Such Action');
    }
  };

  // pagination
  const handlePageChange = (newPageNumber: number): void => {
    setPageNumber(newPageNumber);
  };

  const refreshQuotes = () => {
    hideModal();
    getQuotes(quoteType, pageNumber)
      .then((res: any) => {
        const response = [...res?.data?.content];
        const mapedData = mapData(response);
        setQuotes([...mapedData]);
        mountedRef.current = false;
      })
      .catch((err: any) => console.log(err));
  };

  // TODO Permisions && Actions
  const actionsDefs = {
    field: 'actions',
    headerName: 'Actions',
    // flex: 2,
    flex: 2,
    minWidth: quoteType === QuoteTypes.ACCEPTED ? 350 : 150,
    headerAlign: 'center',
    align: 'center',
    headerClassName: 'super-app-theme--header',
    renderCell: (value: any) => (
      <div className={classes.actions}>
        {
          <Button
            variant="contained"
            color="primary"
            style={{ fontSize: '0.6rem', backgroundColor: '#467495' }}
            onClick={() => handleUpdateDocument(value)}
          >
            Update
          </Button>
        }
        {
          <Button
            variant="contained"
            color="primary"
            style={{ fontSize: '0.6rem', backgroundColor: '#467495' }}
            onClick={() => handleReview(value)}
          >
            Review
          </Button>
        }
        {quoteType === QuoteTypes.ACCEPTED && hasAclRoleName('Administrator') && (
          <Button
            disabled={Boolean(value.row.status === QuoteTypes.APPROVED)}
            variant="contained"
            color="primary"
            name="Approve"
            sx={{
              [`&.${buttonClasses.contained}`]: {
                backgroundColor: '#467495',
              },
              [`&.${buttonClasses.disabled}`]: {
                backgroundColor: '#d3d3d3',
              },
            }}
            style={{ fontSize: '0.6rem' }}
            onClick={event =>
              showModalService.showConfirmModal(
                event as any,
                value,
                handleStatusChange,
                'Confirm approving please.'
              )
            }
          >
            Approve
          </Button>
        )}
        {quoteType === QuoteTypes.ACCEPTED && hasAclRoleName('Administrator') && (
          <Button
            variant="contained"
            color="primary"
            name="Reconcile"
            style={{ fontSize: '0.6rem', backgroundColor: '#467495' }}
            onClick={event =>
              showModalService.showConfirmModal(
                event as any,
                value,
                handleStatusChange,
                'Confirm reconcile please.'
              )
            }
          >
            Reconcile
          </Button>
        )}

        {quoteType === QuoteTypes.ACCEPTED && (
          <Button
            variant="contained"
            color="primary"
            name="Decline"
            style={{ fontSize: '0.6rem', backgroundColor: '#467495' }}
            onClick={event =>
              showModalService.showConfirmModal(
                event as any,
                value,
                handleStatusChange,
                'Confirm decline please.'
              )
            }
          >
            Decline
          </Button>
        )}
        {quoteType === QuoteTypes.LIVE_PAID && (
          <Button
            variant="contained"
            color="primary"
            name="Decline"
            style={{ fontSize: '0.6rem', backgroundColor: '#467495' }}
            onClick={event => showModalService.showSettlementModal(value.row)}
          >
            Close
          </Button>
        )}
      </div>
    ),
  };

  /**
   * Map response data ??
   * @param data
   * @returns
   */
  function mapData(data: any[]) {
    return data.map((item: any, index: number) => ({
      ...item,
      policyNumber: item?.policyNumber,
      renewalDate: item?.renewalDate,
      firstName: item?.organisation?.firstName,
      lastName: item?.organisation?.lastName,
      phone: item?.organisation?.phone,
      address1: item?.organisation?.address1,
      address2: item?.organisation?.address2,
      city: item?.organisation?.address4,
      postCode: item?.organisation?.postCode,
      organisation: item?.organisation?.organisation,
    }));
  }

  React.useEffect(() => {
    setQuoteType(props.quoteType);
    setSearch('');
    setPageNumber(0); // reset page number when quote type changes
  }, [props]);

  React.useEffect(() => {
    if (quoteType !== null) {
      getQuotes(quoteType, pageNumber)
        .then((res: any) => {
          const response = [...res?.data?.content];
          const mapedData = mapData(response);
          setQuotes([...mapedData]);
          setTotalPages(Math.ceil(res?.data?.totalCount / 10));
          mountedRef.current = false;
        })
        .catch((err: any) => console.log(err));
    }
  }, [quoteType, pageNumber]);

  React.useEffect(() => {
    resolveColumns();
  }, [quoteType]);

  const resolveColumns = (): void => {
    let updatedColumnsDefs = [...columnsDefs];

    if (quoteType === QuoteTypes.ACCEPTED) {
      const quoteIdColumn = {
        field: 'surveyorsArbitrationQuoteId',
        headerName: 'Quote Id',
        flex: 1,
        maxWidth: 120,
      };
      updatedColumnsDefs = updatedColumnsDefs.filter(item => item.field !== 'policyNumber');
      updatedColumnsDefs.unshift(quoteIdColumn);
    } else if (
      quoteType === QuoteTypes.LIVE_PAID &&
      !updatedColumnsDefs.some(e => e.field === 'policyNumber')
    ) {
      const policyNumberColumn = {
        field: 'policyNumber',
        headerName: 'Policy Number',
        flex: 1,
        maxWidth: 120,
      };
      updatedColumnsDefs = updatedColumnsDefs.filter(
        item => item.field !== 'surveyorsArbitrationQuoteId'
      );
      updatedColumnsDefs.unshift(policyNumberColumn);
    }
    columnsDefs = updatedColumnsDefs;
  };

  React.useEffect(() => {
    if (!mountedRef.current) {
      searchQuotes({ type: quoteType, searchValue: search })
        .then((res: any) => {
          const response = [...res?.data?.content];
          const mapedData = mapData(response);
          setQuotes([...mapedData]);
          setTotalPages(Math.ceil(res?.data?.totalElements / 10));
        })
        .catch((err: any) => {
          const { data } = err['response'];
          if (data?.status === 400) {
            setQuotes([]);
            refreshQuotes();
          }
        });
    }
  }, [search]);

  /**
   * Handle search value
   * @param e event from autocomplete
   */
  const handleSearch = (e: any) => {
    setSearch(e.target.value);
  };

  return (
    <div className={classes.grow}>
      <div className={[classes.root, classes.root_ext].join(' ')}>
        <Title>{quoteType === QuoteTypes.ACCEPTED ? 'Quotes' : 'Policies'}</Title>
        <Stack
          spacing={2}
          gap="5px"
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          mb={2}
        >
          <TextField
            onChange={handleSearch}
            value={search ?? ''}
            InputProps={{
              endAdornment: (
                <React.Fragment>
                  <SearchIcon />
                </React.Fragment>
              ),
            }}
          />
        </Stack>
        <DataGrid
          disableColumnMenu={true}
          pagination
          autoHeight={true}
          getRowId={row => row.surveyorsArbitrationQuoteId}
          rows={quotes}
          columns={[...columnsDefs, actionsDefs]}
          initialState={initialStateDataGrid as any}
          pageSize={10}
          sx={{ ...dataGridStyle }}
          components={{
            Pagination: props => (
              <CustomPagination
                {...props}
                count={totalPages}
                page={pageNumber}
                onPageChange={handlePageChange}
              />
            ),
          }}
        />
      </div>
    </div>
  );
}
