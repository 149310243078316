import { AddressDto } from './AddressDto';

export class PersonDto {
  firstName: string = ''; //NotNull,NotEmpty,NotBlank
  lastName: string = '';
  salutation: string = '';
  maritalStatus: string = '';
  emailAddress: string = ''; //NotNull,NotEmpty,NotBlank
  phone: string = '';
  address: AddressDto = new AddressDto();
  companyName: string = '';

  public constructor(init?: Partial<PersonDto>) {
    Object.assign(this, init);
  }
}
