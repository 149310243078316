export class AddressDto{
    flatNo: string = "";
    address1: string = ""; 
    address2: string = "";
    address3: string = "";
    address4: string = "";
    address5: string = "";
    postCode: string = "";
    
    public constructor(init?:Partial<AddressDto>) {
        Object.assign(this, init);
    }
   }