import setAuthToken from '../common/setAuthToken';

const hasAclPermissionName = AclPermissionName => {
  //ACL in Role
  let user = JSON.parse(localStorage.getItem('user'));
  if (user !== null) {
    for (let role of user.roleDtoResponses) {
      for (let acl of role.aclPermissionSet) {
        if (acl.name === AclPermissionName) {
          return true;
        }
      }
    }
    //Acl for user
    return user.aclPermissionDTOResponseList.find(x => x.name === AclPermissionName) === undefined
      ? false
      : true;
  }
  return false;
};

const hasAclPermissionId = AclPermissionId => {
  let user = JSON.parse(localStorage.getItem('user'));
  return user.aclpermissionIds.find(AclPermissionId) === undefined ? false : true;
};

const hasAclRoleId = AclRoleId => {
  let user = JSON.parse(localStorage.getItem('user'));
  if (user !== null) {
    for (let role of user.roleDtoResponses) {
      if (role.id === AclRoleId) {
        return true;
      }
    }
  }
  return false;
};

const hasAclRoleName = roleName => {
  try {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user !== null) {
      for (let role of user.roleDtoResponses) {
        if (role.name === roleName) {
          return true;
        }
      }
    }
    return false;
  } catch (e) {
    console.error('Parsing error:', e);
    // Handle error appropriately for your application
    return false;
  }
};

const reloadPermissions = () => {
  let user = JSON.parse(localStorage.getItem('user'));
  setAuthToken()
    .get('/v2/user/getAllRolesByUserId/' + user.id + '?page=0&limit=50')
    .then(res => {
      user.roleDtoResponses = res.content;
      localStorage.setItem('user', user);
    });
  return true;
};
export {
  hasAclPermissionName,
  hasAclPermissionId,
  hasAclRoleId,
  hasAclRoleName,
  reloadPermissions,
};
