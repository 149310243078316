import React, { useState, useEffect } from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const SegmentTitle = ({ title }) => {
  const theme = createTheme({
    overrides: {
      MuiToolbar: {
        regular: {
          backgroundColor: '#467495',
          height: '32px',
          minHeight: '32px',
          '@media(min-width:600px)': {
            minHeight: '48px',
          },
        },
      },
    },
  });
  const toolbarStyle = {
    customizeToolbar: {
      minHeight: 36,
      maxHeight: 50,
    },
  };

  return (
    <MuiThemeProvider theme={theme}>
      <div>
        <AppBar position="static">
          <Toolbar style={toolbarStyle}>
            <Typography variant="subtitle2" color="inherit" component="div">
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
      </div>
    </MuiThemeProvider>
  );
};
export default SegmentTitle;
