import React, { useState } from 'react';

const AuthContext = React.createContext({
  token: '',
  user: {},
  isLoggedIn: false,
  login: (token, user) => {},
  logout: () => {},
  quote: {},
  setQuote: quote => {},
  claim: {},
  setClaim: claim => {},
});

export const AuthContextProvider = props => {
  const initialToken = localStorage.getItem('token') ? localStorage.getItem('token') : null;
  const initialUser = localStorage.getItem('user') ? localStorage.getItem('user') : {};
  const [token, setToken] = useState(initialToken);
  const [user, setUser] = useState(initialUser);

  const [quote, setQuote] = useState([]);
  const [claim, setClaim] = useState({});

  const userIsLoggedIn = token !== null;

  const loginHandler = (token, user) => {
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
    setToken(token);
    setUser(user);
  };

  const logoutHandler = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setToken(null);
    setUser(null);
  };

  const setQuoteHandler = quote => {
    setQuote(quote);
  };

  const setClaimHandler = claim => {
    setClaim(claim);
  };

  const contextValue = {
    token: token,
    user: user,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
    quote: quote,
    setQuote: setQuoteHandler,
    claim: claim,
    setClaim: setClaimHandler,
  };

  return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>;
};

export default AuthContext;
