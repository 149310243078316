import React, { useEffect } from 'react';
import {
  makeStyles,
  Container,
  CssBaseline,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Tooltip,
  Typography,
  Button,
  TextareaAutosize,
  Input,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import SegmentTitle from '../components/SegmentTitle';
import { getDocuments, updateDocuments } from '../services/QuotesService';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DownloadingSharpIcon from '@mui/icons-material/DownloadingSharp';
import DownloadService from '../arbitration-insurance/services/DownloadService';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Divider from '@material-ui/core/Divider';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles({
  logo: {
    flexGrow: 1,
  },
  media: {
    height: 200,
  },
  imageLogo: {
    width: 110,
    marginTop: 8,
  },
  topMargin: {
    marginTop: 50,
  },
});

const UpdateDocuments = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [data, setData] = React.useState(null);
  const [accordions, setAccordions] = React.useState([]);

  const [isFetchingDocuments, setIsFetchingDocuments] = React.useState(true);

  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [successMessage, setSuccessMessage] = React.useState(null);

  const hasFilesUploaded = () => {
    return accordions.some(accordion => accordion.files.length > 0);
  };

  const handleUpdateDocuments = async () => {
    try {
      setIsLoading(true);
      setError(null);
      setSuccessMessage(null);

      // Prepare the Blob payload
      const payloads = await prepareBlobPayload(); // Make sure to create this function

      // Call the updateDocuments function with the Blob payload
      await updateDocuments(id, payloads);

      setSuccessMessage('Documents updated successfully!');
    } catch (err) {
      const errorMessage =
        err.response?.data?.message || 'An error occurred while updating documents.';
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = (index, e) => {
    const file = e.target.files[0];
    if (file) {
      setAccordions(prev => {
        const updatedAccordions = [...prev];
        updatedAccordions[index] = {
          ...updatedAccordions[index],
          files: [
            ...updatedAccordions[index].files,
            {
              fileBlob: file, // Store the blob data
              description: updatedAccordions[index].currentDescription,
              name: file.name,
              type: file.type,
              size: file.size,
            },
          ],
          currentDescription: updatedAccordions[index].currentDescription,
        };
        return updatedAccordions;
      });
    }
  };

  const handleAddClick = () => {
    setAccordions(prev => [...prev, { files: [], currentDescription: '' }]);
  };

  const handleDeleteAccordion = index => {
    setAccordions(prev => {
      const updatedAccordions = [...prev];
      updatedAccordions.splice(index, 1);
      return updatedAccordions;
    });
  };

  const prepareBlobPayload = () => {
    const formData = new FormData();
    const documents = [];

    accordions.forEach(accordion => {
      accordion.files.forEach(file => {
        formData.append('files', file.fileBlob);

        // Add each document's data to the documents array
        documents.push({
          description: file.description || '',
          fileName: file.name,
        });
      });
    });

    // Convert the documents array to a JSON string and then to a blob
    const documentsBlob = new Blob([JSON.stringify(documents)], { type: 'application/json' });

    // Append the blob to the formData
    formData.append('documents', documentsBlob);

    return formData;
  };

  const handleDownload = file => {
    DownloadService.downloadFile(file);
  };

  useEffect(() => {
    setIsFetchingDocuments(true);
    getDocuments(id)
      .then(res => {
        setData(res.data);
        console.log(res.data);
      })
      .catch(error => {
        console.error('Error fetching documents:', error);
      })
      .finally(() => {
        setIsFetchingDocuments(false);
      });
  }, []);

  return (
    <>
      <CssBaseline />
      <Container maxWidth="md" className={classes.topMargin}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SegmentTitle title={'Documents and attachments'} />

            {isFetchingDocuments ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '50px',
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <div style={{ marginTop: '1rem', marginBottom: '2rem' }}>
                {data?.arbitrationQuoteId && (
                  <Accordion style={{ background: '#fbfbfb', marginBottom: '15px' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{ color: '#467495' }} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{ color: '#467495' }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <CloudDownloadIcon style={{ marginRight: '1rem' }} color="primary" />
                        <Typography component={'div'} variant="h5">
                          Your Documents and Attachments
                        </Typography>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{ display: 'grid', rowGap: '20px', alignContent: 'center', scale: '0.9' }}
                    >
                      {data?.documentDtoList.length ? (
                        data?.documentDtoList.map((file, index) => (
                          <Container
                            key={`${index}_list_item_key`}
                            style={{
                              display: 'flex',
                              borderBottom: 'thin #467495 solid',
                              padding: '0 0 5px 0',
                              alignItems: 'center',
                            }}
                          >
                            <div style={{ flex: 5, fontWeight: 'bold' }}>{file.fileName}</div>
                            <div
                              style={{
                                flex: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'end',
                              }}
                            >
                              <Tooltip title="Download">
                                <IconButton
                                  aria-label="download"
                                  onClick={() => handleDownload(file)}
                                >
                                  <DownloadingSharpIcon sx={{ color: '#467495' }} />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </Container>
                        ))
                      ) : (
                        <Container
                          style={{
                            display: 'flex',
                            borderBottom: 'thin #467495 solid',
                            padding: '0 0 5px 0',
                          }}
                        >
                          <div
                            style={{
                              flex: 1,
                              fontWeight: 'bold',
                              color: '#467495',
                              textAlign: 'center',
                            }}
                          >
                            No documents and attachments.
                          </div>
                        </Container>
                      )}
                    </AccordionDetails>
                  </Accordion>
                )}
              </div>
            )}
          </Grid>

          <Grid item xs={12}>
            <SegmentTitle title={'Add more documents and attachments'} />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" style={{ marginBottom: '1rem' }}>
              Would you like to add any documents or attachments to your claim?
            </Typography>
            <Button
              size="large"
              variant="outlined"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleAddClick}
              style={{ marginBottom: '1rem' }}
            >
              Add
            </Button>
          </Grid>

          {accordions.map((accordionData, accordionIndex) => (
            <Grid item xs={12} key={accordionIndex}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <Typography style={{ flexGrow: 1 }}>Attachment {accordionIndex + 1}</Typography>
                    <IconButton edge="end" onClick={() => handleDeleteAccordion(accordionIndex)}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <Typography variant="body1">File description (optional):</Typography>
                    <TextareaAutosize
                      value={accordionData.currentDescription}
                      onChange={e => {
                        const value = e.target.value;
                        setAccordions(prev => {
                          const updatedAccordions = [...prev];
                          updatedAccordions[accordionIndex].currentDescription = value;
                          return updatedAccordions;
                        });
                      }}
                      rowsMin={3}
                      style={{ marginBottom: '2rem', width: '100%', fontSize: '1rem' }}
                    />
                    {/* Hidden input for file selection */}
                    <Input
                      type="file"
                      style={{ display: 'none' }}
                      id={`file-input-${accordionIndex}`}
                      onChange={e => handleFileChange(accordionIndex, e)}
                    />

                    <Divider />

                    <div style={{ marginBottom: '1rem', marginTop: '2rem' }}>
                      <Typography variant="body1">
                        Attach any documentary evidence here (images, pdfs and documents only)
                      </Typography>
                    </div>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<CloudUploadIcon />}
                      onClick={() =>
                        document.getElementById(`file-input-${accordionIndex}`).click()
                      }
                    >
                      Attach File
                    </Button>
                    {accordionData.files.map((fileInfo, fileIndex) => (
                      <div key={fileIndex} style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                        <Typography>
                          <span style={{ color: '#467495', fontWeight: 'bold' }}>Filename:</span>{' '}
                          {fileInfo.name}
                        </Typography>
                        <Typography>
                          <span style={{ color: '#467495', fontWeight: 'bold' }}>Filetype:</span>{' '}
                          {fileInfo.type}
                        </Typography>
                        <Typography>
                          <span style={{ color: '#467495', fontWeight: 'bold' }}>Size:</span>{' '}
                          {fileInfo.size} bytes
                        </Typography>
                      </div>
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
          <Grid item xs={12} style={{ marginTop: '1rem' }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleUpdateDocuments}
              disabled={isLoading || !hasFilesUploaded()}
              startIcon={isLoading ? <CircularProgress size={20} /> : null}
            >
              Update Documents
            </Button>
            {error && (
              <Typography color="error" style={{ marginTop: '1rem' }}>
                {error}
              </Typography>
            )}
            {successMessage && (
              <Typography color="primary" style={{ marginTop: '1rem' }}>
                {successMessage}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default UpdateDocuments;
