import React from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

import { DataGrid } from '@material-ui/data-grid';
import Container from '@material-ui/core/Container';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { useEffect, useState, useRef } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import ListItemIcon from '@mui/material/ListItemIcon';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import setAuthToken from '../common/setAuthToken';

const API_URL = process.env.REACT_APP_API_URL;

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  root: {
    '& .super-app-theme--header': {
      backgroundColor: '#FAFAFA',
      color: 'red',
    },
    '& .MuiSvgIcon-root': {
      right: '75px',
    },
    '& .MuiDataGrid-cell': {
      textAlign: 'center',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      whiteSpace: 'pre-wrap',
      lineHeight: '20px',
    },
    '& .MuiDataGrid-menuIconButton': {
      display: 'none',
    },
    // "& .MuiOutlinedInput-notchedOutline": {
    //   right: "-45px",
    // },
  },
}));

const searchStyles = {
  borderStyle: 'solid',
  borderColor: '#C4C4C4',
  borderWidth: '1px',
  borderRadius: '5px',
  height: '54px',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: '0px !important',
};

// const rows = [
//   { id: 1, lastName: "Snow", firstName: "Jon", age: 35, description:"string" },
//   { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42, description:"string" },
//   { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45, description:"string" },
//   { id: 4, lastName: "Stark", firstName: "Arya", age: 16, description:"string" },
//   { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: 20, description:"string" },
//   { id: 6, lastName: "Melisandre", firstName: null, age: 150, description:"string" },
//   { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44, description:"string" },
//   { id: 8, lastName: "Frances", firstName: "Rossini", age: 36, description:"string" },
//   { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65, description:"string" },
// ];

const columns = [
  { field: 'id', headerName: 'ID', width: 100 },
  { field: 'name', headerName: 'Name', width: 140 },
  { field: 'isDeleted', headerName: 'isDeleted', width: 140 },
  { field: 'appModuleId', headerName: 'appModuleId', width: 140 },

  // { field: "name", headerName: "Last name", width: 140 },
  // { field: "age", headerName: "age", width: 90 },

  // {
  //   field: "age",
  //   headerName: "Age",
  //   type: "number",
  //   width: 90,
  // },
  // {
  //   field: "fullName",
  //   headerName: "Full name",
  //   description: "This column has a value getter and is not sortable.",
  //   sortable: false,
  //   width: 180,
  //   valueGetter: (params) =>
  //      `${params.row.firstName + " "}  ${
  //       params.row.lastName
  //      }`,
  // },
  { field: 'description', headerName: 'Description', width: 140 },
  // {
  //   field: "actions",
  //   headerName: "Actions",
  //   width: 390,
  //   headerClassName: "super-app-theme--header",
  //   renderCell: () => (
  //     <ButtonGroup
  //       style={{ width: "250px" }}
  //       variant='contained'
  //       aria-label='outlined primary button group'
  //     >
  //       <Button disabled style={{ fontSize: "0.6rem" }}>
  //         Renew
  //       </Button>
  //       <Button
  //         style={{
  //           backgroundColor: "white",
  //           color: "black",
  //           fontSize: "0.6rem",
  //         }}
  //       >
  //         Update
  //       </Button>
  //       <Button color='error' style={{ fontSize: "0.6rem" }}>
  //         Cancel
  //       </Button>
  //       <Button style={{ fontSize: "0.6rem" }}>Claim</Button>
  //     </ButtonGroup>
  //   ),
  // },
];

const AclPermission = () => {
  const classes = useStyles();
  const history = useNavigate();
  const [aclPermission, setAclPermission] = useState({});
  const [renState, renderState] = useState(true);

  useEffect(() => {
    setAuthToken()
      .get(`${API_URL}v2/aclpermission/all?page=0&limit=50`)
      .then(res => {
        setAclPermission(res);
      });
  }, []);

  const rows = aclPermission?.data?.content ? aclPermission.data.content : [];

  rows.forEach(element => {
    element.id = element.id;
  });

  const mounted = useRef();

  useEffect(() => {
    if (!mounted.current) {
      renderState(!renState);
    } else {
      console.log(true);
    }
  }, []);

  return (
    <div className={classes.grow}>
      <div className={classes.root} style={{ height: 400, width: '63%', margin: '2rem auto' }}>
        <Typography variant="h4" gutterBottom component="h1">
          Permissions
        </Typography>
        <Stack spacing={2} direction="row" alignItems="center" justifyContent="flex-start" mb={2}>
          <Autocomplete
            sx={{ width: 300 }}
            freeSolo
            id="free-solo-2-demo"
            disableClearable
            renderInput={params => (
              <TextField
                {...params}
                label="Search input"
                InputProps={{
                  ...params.InputProps,
                  type: 'search',
                }}
              />
            )}
          />
          <ListItemIcon sx={{ ...searchStyles }}>
            <SearchIcon fontSize="medium" />
          </ListItemIcon>
        </Stack>
        {rows !== null ? (
          <DataGrid rows={rows} columns={columns} pageSize={5} rowsPerPageOptions={[5, 10, 20]} />
        ) : null}
      </div>
    </div>
  );
};

export default AclPermission;
