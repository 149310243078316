import { PersonDto } from '../../models/PersonDto';

export class ArbitrationDto {
  proposerDto: PersonDto = new PersonDto();
  opponentDto: PersonDto = new PersonDto();
  supervisorFirstName: string = '';
  supervisorLastName: string = '';
  supervisorPhone: string = '';
  supervisorEmail: string = '';

  // opponentType: string = '';
  qsOpinionSOught: boolean | string = '';
  counselOpinionSought: boolean | string = '';

  opponentName: string = '';
  opponentRepresentative: string = '';
  opponentType: string = '';
  coverLevel: string = '';
  amountDisputed: string = '';
  amountExpected: string = '';
  prospectOfSuccess: string = '';

  public constructor(init?: Partial<ArbitrationDto>) {
    Object.assign(this, init);
  }
}
